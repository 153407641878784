<template>
  <div class="listen_content">
    <div class="inner_content">
      <div class="text_center" v-if="startAnswerQues && allListLength > 1">
        <span class="game_number">
          {{ currentQIndex + 1 + usedListLength }}/{{ allListLength }}
        </span>
      </div>
      <div class="text_content" v-if="!startAnswerQues">
        {{ content }}
      </div>
      <div v-else class="choose_question_box">
        <template v-for="(item, itemIndex) in questionList">
          <div
            v-if="currentQIndex == itemIndex"
            class="choose_question_item"
            :key="item.id"
          >
            <div class="question_content">
              {{ item.content }}
            </div>
            <div
              v-for="(linkItem, linkIndex) in item.link"
              :key="linkItem.id"
              class="single_question"
            >
              <div class="question_title">{{ linkItem.question.title }}</div>
              <a-radio-group
                class="radio_group flex_wrap"
                @change="radioChange($event, linkItem.question_id, linkIndex)"
              >
                <label
                  class="label_item full_width"
                  v-for="(itemImg, itemImgIndex) in linkItem.question.items"
                  :key="itemImgIndex"
                >
                  <a-radio
                    :color="themeColor"
                    class="lable_radio"
                    :value="itemImg.id"
                  ></a-radio>
                  <div>{{ indexPrefix[itemImgIndex] }}:</div>
                  <span class="radio_text">{{ itemImg.option_text }}</span>
                </label>
              </a-radio-group>
            </div>
          </div>
        </template>
      </div>
    </div>
    <ExamFooter
      @goOnPlay="goOnPlay"
      :isNewCountDown="isNewCountDown"
      @endDown="countDownEnd"
      :source="source"
      :currentItem="currentItem"
    ></ExamFooter>
  </div>
</template>

<script>
import ExamFooter from '../exam-footer';
import globalConfig from '@/store/global_config';
import {
  PUBLIC_DATA,
  indexToUpper,
  indexPrefix,
  showLoading,
  hideLoading
} from '@/utils/common';
import { formatUsedData } from './index';
const { imgUrl } = PUBLIC_DATA;
export default {
  components: { ExamFooter },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    }
  },
  props: {
    itemInfo: {
      default: () => {},
      type: Object
    },
    bookId: {
      default: '',
      type: String
    },
    bookChapterId: {
      default: '',
      type: String
    },
    typeId: {
      default: '',
      type: String
    },
    gameId: {
      default: '',
      type: String
    },
    source: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      indexPrefix: indexPrefix,
      imgUrl: imgUrl,
      // 当前问题索引
      currentQIndex: 0,
      // 播放状态
      playStatus: false,
      // 倒计时文本
      countDownText: '',
      // 开始答题
      startAnswerQues: false,
      // 作业要求
      content: '',
      // 问题列表
      questionList: [],
      // 倒计时数组(包含类型等)
      countDownArr: [],
      // 倒计时索引
      countDownIndex: 0,
      // 当前题目选择的答案
      userRaidoChoose: [],
      // 总共的小题数量
      allListLength: 0,
      // 做过的小题数
      usedListLength: 0,
      isNewCountDown: false,
      currentItem: {}
    };
  },

  mounted() {
    const { introduce, questions: list } = this.itemInfo;
    this.introduce = introduce || {};
    this.allListLength = list.length;
    const {
      content,
      file_url: firstClaimUrl,
      answer_time: answerTime,
      tips_time: tipsTime,
      frequency
    } = this.introduce;
    this.content = content;
    // 如果是考试模式, 不保存进度
    const { newArr: filterUsed, onlyFilterNull } = formatUsedData(list);
    let questions = filterUsed;
    if (questions.length === 0) {
      this.$emit('end');
      return;
    }
    this.usedListLength = list.length - questions.length;
    // 如果是考试模式就是没做过，练习模式 第一个是否used
    globalConfig.commit(
      'changeFirstQuestion',
      !onlyFilterNull[0].link[0].question.used
    );
    this.questionList = questions;
    const newArr = [];
    // 第一个是要求;
    newArr.push({
      type: 'audio',
      src: firstClaimUrl,
      text: '答题要求播放中',
      needPlusTime: true,
      btnType: 'jump',
      startAnswerQues: false
    });
    questions.forEach(item => {
      const {
        id,
        file_url: questionClaimUrl,
        audio_url: itemAudioUrl,
        tips_time: itemTipsTime,
        answer_time: itemAnswerTime,
        frequency: itemFrequency
      } = item;
      const hisTipsTime = itemTipsTime || tipsTime;
      const hisAnswerTime = itemAnswerTime || answerTime;
      const hisFrequency = itemFrequency || frequency;
      newArr.push({
        type: 'audio',
        src: questionClaimUrl,
        text: '答题要求播放中',
        startAnswerQues: true,
        btnType: 'jump'
      });
      newArr.push({
        type: 'tips',
        time: hisTipsTime,
        text: '请阅读相关题目',
        btnType: 'jump',
        startAnswerQues: true
      });
      for (let i = 0; i < hisFrequency; i++) {
        newArr.push({
          type: 'audio',
          src: itemAudioUrl,
          text: `第${indexToUpper[i]}遍`,
          btnType: 'jump',
          startAnswerQues: true
        });
      }
      newArr.push({
        id,
        type: 'tips',
        time: hisAnswerTime,
        isEnd: true,
        text: '请选择',
        btnType: 'submit',
        startAnswerQues: true
      });
    });
    this.countDownArr = newArr;
    this.startNextCountDown();
  },

  methods: {
    goOnPlay() {
      this.startNextCountDown();
    },

    // 单选更改
    radioChange(event, parentId, linkIndex) {
      const targetValue = event.target.value;
      const newArr = [...this.userRaidoChoose];
      // 如果是假，说明是新增
      newArr[linkIndex] = {
        id: String(parentId),
        user_answer: String(targetValue)
      };
      this.userRaidoChoose = [...newArr];
    },

    startNextCountDown() {
      this.isRequesting = false;
      const countDownItem = this.countDownArr[this.countDownIndex];
      this.currentItem = { ...countDownItem };
      setTimeout(() => {
        this.startAnswerQues = countDownItem.startAnswerQues;
        this.isNewCountDown = !this.isNewCountDown;
      }, 0);
    },

    countDownEnd() {
      // 验证当前播放结束的那个是不是这道题目的最后一个
      const countDownItem = this.countDownArr[this.countDownIndex];
      const { isEnd, id: questionId } = countDownItem || {};
      //  倒计时结束，判断当前是不是最后一个，
      // 如果是就请求保存结果，
      // 成功后在继续播放下一个
      if (isEnd) {
        // 是这道题的最后一个，需要触发保存接口，然后才能继续下一题
        this.handleSaveUserData(questionId);
        return;
      }
      this.countDownIndex += 1;
      this.startNextCountDown();
    },

    // 保存用户数据
    handleSaveUserData(questionId) {
      const currentLinkArr = this.questionList[this.currentQIndex];
      const questionArr = [];
      currentLinkArr.link.forEach((itemLink, itemLinkIndex) => {
        const { question } = itemLink;
        const { id } = question;
        const { user_answer: userAnswer } =
          this.userRaidoChoose[itemLinkIndex] || {};
        questionArr.push({
          id: String(id),
          user_answer: userAnswer || ''
        });
      });
      const payload = {
        book_chapter_id: this.bookChapterId,
        gameId: this.gameId,
        typeId: this.typeId,
        question: questionArr,
        question_id: questionId,
        book_id: this.bookId,
        source: this.source
      };
      if (this.isRequesting) {
        return;
      }
      this.isRequesting = true;
      showLoading();
      globalConfig
        .dispatch('submitUnitUserAnswer', payload)
        .then(() => {
          hideLoading();
          globalConfig.commit('changeFirstQuestion', false);
          this.playStatus = false;
          if (this.currentQIndex + 1 >= this.questionList.length) {
            console.log('已经是本类型最后一个了，需要触发父组件事件');
            this.playStatus = false;
            this.$emit('end');
            this.isRequesting = false;
            return;
          }
          this.currentQIndex++;
          this.userRaidoChoose = [];
          this.countDownIndex += 1;
          this.startNextCountDown();
        })
        .catch(() => {
          hideLoading();
          this.isRequesting = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../exam.less';
.listen_content1 {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    flex: 2;
    padding: 30rpx;
    box-sizing: border-box;
    background: white;
    overflow-y: scroll;
    border-radius: 8px;
    .title {
      font-size: 40rpx;
      background: #e9e9e9;
      border-radius: 30rpx;
      padding: 0 30rpx;
      color: gray;
      display: inline-block;
      margin-bottom: 20rpx;
    }
    .text_content {
      text-align: left;
      text-indent: 2em;
      font-size: 32rpx;
      color: #666;
    }
    .outer_question {
      text-align: left;
      box-sizing: border-box;
      .question_content {
        font-size: 32rpx;
        text-indent: 2em;
        color: #666;
      }
      .question_outer {
        padding: 20rpx 0;
        box-sizing: border-box;
        .radio_group {
          margin-top: 20rpx;
          .label_item {
            margin-bottom: 20rpx;
            display: flex;
            .lable_radio {
              margin-right: 10rpx;
              position: relative;
              top: -5rpx;
            }
            .option_text {
              margin-left: 10rpx;
            }
          }
        }
      }
    }
  }
}
</style>
