<template>
  <div class="listen_content">
    <div class="inner_content">
      <div class="text_center" v-if="startAnswerQues && allListLength > 1">
        <span class="game_number"
          >{{ curtQIndex + 1 + usedListLength }}/{{ allListLength }}</span
        >
      </div>
      <div class="text_content claim_text" v-if="!startAnswerQues">
        {{ content }}
      </div>
      <template v-else>
        <template v-for="(item, itemIndex) in questionList">
          <div
            v-if="curtQIndex == itemIndex"
            class="outer_question"
            :key="item.id"
          >
            <div class="text_content">{{ item.tip_title }}</div>
            <div class="scene_tips">
              {{ item.title }}
            </div>
          </div>
        </template>
      </template>
    </div>
    <ExamFooter
      @endRecord="recorderEnd"
      @goOnPlay="goOnPlay"
      @endDown="countDownEnd"
      :source="source"
      :currentItem="currentItem"
      :isNewCountDown="isNewCountDown"
      :recordStatus="recorderStatus"
      :notifyReset="notifyReset"
      needRecord
    ></ExamFooter>
  </div>
</template>

<script>
import ExamFooter from '../exam-footer';
import globalConfig from '@/store/global_config';
import { PUBLIC_DATA, indexToUpper } from '@/utils/common';
import { hideLoading, showLoading } from '../../../../utils/common';
const { imgUrl, defaultRecordUrl } = PUBLIC_DATA;

export default {
  components: { ExamFooter },
  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    recorderStatus() {
      return globalConfig.state.recorderStatus;
    }
  },
  props: {
    itemInfo: {
      default: () => {},
      type: Object
    },
    bookId: {
      default: '',
      type: String
    },
    bookChapterId: {
      default: '',
      type: String
    },
    typeId: {
      default: '',
      type: String
    },
    gameId: {
      default: '',
      type: String
    },
    source: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      imgUrl: imgUrl,
      // 当前问题索引
      curtQIndex: 0,
      // 开始答题
      startAnswerQues: false,
      // 作业要求
      content: '',
      // 问题列表
      questionList: [],
      // 倒计时数组(包含类型等)
      countDownArr: [],
      // 倒计时索引
      countDownIndex: 0,
      // 当前问题
      currentQuestionInfo: {},
      // 正在请求保存数据
      requestLoading: false,
      // 总共的小题数量
      allListLength: 0,
      // 做过的小题数
      usedListLength: 0,
      isNewCountDown: false,
      currentItem: {},
      notifyReset: false
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      const { introduce, questions: list } = this.itemInfo;
      // 制造默认数据
      this.allListLength = list.length;
      this.introduce = introduce || {};
      const {
        content,
        file_url: firstClaimUrl,
        answer_time: answerTime,
        audio_url: audioUrl,
        frequency,
        tips_time: tipsTime
      } = introduce || {};
      this.content = content;
      // 如果是考试模式, 不保存进度
      let questions = this.formatUsedData(list);
      if (questions.length <= 0) {
        this.$emit('end');
        return;
      }
      this.questionList = questions;
      this.usedListLength = this.allListLength - questions.length;
      globalConfig.commit('changeFirstQuestion', !list[0].used);
      const newArr = [];
      // 第一个是要求
      newArr.push({
        type: 'audio',
        src: firstClaimUrl,
        text: '答题要求播放中',
        needPlusTime: true,
        btnType: 'jump'
      });
      questions.forEach(item => {
        const {
          file_url: itemAudioUrl,
          title,
          answer,
          id,
          tip_url: tipUrl
        } = item;
        // 小题提示语
        newArr.push({
          type: 'audio',
          src: tipUrl,
          text: '答题要求播放中',
          btnType: 'jump',
          startAnswerQues: true
        });
        newArr.push({
          type: 'tips',
          time: tipsTime,
          text: '请阅读相关题目',
          btnType: 'jump'
        });
        for (let i = 0; i < frequency; i++) {
          newArr.push({
            type: 'audio',
            src: itemAudioUrl,
            text: `第${indexToUpper[i]}遍`,
            btnType: 'jump'
          });
        }
        // 录音前提示语
        newArr.push({
          type: 'audio',
          src: audioUrl || defaultRecordUrl,
          text: audioUrl ? '答题要求播放中' : '下面开始录音',
          btnType: 'jump',
          isFromLocal: !audioUrl
        });
        newArr.push({
          id,
          question: title,
          type: 'tips',
          time: answerTime,
          isEnd: true,
          text: '录音中',
          btnType: 'record',
          answerObj: JSON.parse(answer)
        });
      });
      this.countDownArr = newArr;
      this.startNextCountDown();
    },

    goOnPlay() {
      this.startNextCountDown();
    },

    // 失败回调函数(播放错误，等失败回调)
    errorCallback() {
      this.countDownIndex = this.countDownIndex - 1;
      this.countDownEnd();
    },

    formatUsedData(list) {
      const newArr = [];
      list.forEach(item => {
        if (!item.used) {
          newArr.push(item);
        }
      });
      return newArr;
    },

    startNextCountDown() {
      const countDownItem = this.countDownArr[this.countDownIndex];
      const { btnType, startAnswerQues } = countDownItem;
      this.btnType = btnType;
      this.notifyReset = false;
      this.currentItem = { ...countDownItem };
      if (startAnswerQues) {
        this.startAnswerQues = true;
      }
      setTimeout(() => {
        this.isNewCountDown = !this.isNewCountDown;
      }, 0);
    },

    countDownEnd() {
      // 如果正在录音还没保存就停止，保存完毕后回调用这个方法
      if (this.requestLoading) {
        return;
      }
      if (this.countDownIndex + 1 >= this.countDownArr.length) {
        console.log('is last question, emit end event');
        this.$emit('end');
        return;
      }
      this.countDownIndex += 1;
      // 验证当前播放结束的那个是不是这道题目的最后一个
      const countDownItem = this.countDownArr[this.countDownIndex];
      const { isEnd } = countDownItem;
      this.currentQuestionInfo = { ...countDownItem };
      //  倒计时结束，判断当前是不是录音选项，
      if (isEnd) {
        this.startRecord();
        return;
      }
      // 如果不是就开始下一个倒计时
      this.startNextCountDown();
    },

    // 停止录音
    recorderEnd() {
      showLoading('评测中');
      globalConfig.commit('stopRecorder');
    },

    formatAnswerText(answerArr) {
      const newArr = [];
      answerArr.forEach(item => {
        newArr.push({
          text: item
        });
      });
      return newArr;
    },

    recordObj({ answerObj, question, time }) {
      const newObj = {
        coreType: 'en.pqan.score',
        // 设置可能的正确回答;lm 中的每个 text 表示一种正确的回答
        // 提问问题的文本
        quest_ans: question,
        evalTime: time * 1000,
        lm: this.formatAnswerText(answerObj.text),
        // key 包括关键点可能的表述方式;关键点对打分的影响很大
        key: this.formatAnswerText(answerObj.key),
        attachAudioUrl: 1,
        phdet: 1,
        syllable: 1,
        syldet: 1,
        symbol: 1,
        precision: 0.1,
        rateScale: 1.1
      };
      return newObj;
    },

    startRecord() {
      const {
        id: questionId,
        answerObj,
        question,
        time
      } = this.currentQuestionInfo;
      const { score, num } = this.introduce;
      globalConfig.commit('startRecorder', {
        elId: 'iRecorder',
        recordParams: {
          ...this.recordObj({ answerObj, question, time }),
          rank: score / num
        },
        getEvalMessage: data => this.handleSaveRecordData(data, questionId),
        startCallback: () => {
          this.requestLoading = true;
          hideLoading();
          this.startNextCountDown();
        },
        stopCallback: () => {
          showLoading('评测中');
        }
      });
    },

    handleSaveRecordData(data, questionId) {
      showLoading('请稍候');
      const payload = {
        book_chapter_id: this.bookChapterId,
        gameId: this.gameId,
        typeId: this.typeId,
        question_id: questionId,
        book_id: this.bookId,
        source: this.source,
        read_result: data
      };
      globalConfig.dispatch('submitUnitUserAnswer', payload).then(() => {
        setTimeout(() => {
          globalConfig.commit('changeFirstQuestion', false);
          this.curtQIndex += 1;
          this.requestLoading = false;
          this.countDownEnd();
        }, 1000);
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../exam.less';
.listen_content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    flex: 2;
    box-sizing: border-box;
    background: white;
    overflow-y: scroll;
    border-radius: 8px;
    .title {
      font-size: 40rpx;
      background: #e9e9e9;
      border-radius: 30px;
      padding: 0 30px;
      color: gray;
      display: inline-block;
      margin-bottom: 20px;
    }
    .text_content {
      text-align: left;
      color: #666;
      &.claim_text {
        text-indent: 2em;
      }
    }
    .outer_question {
      text-align: left;
      .scene_tips {
        font-size: 1rem;
        margin-top: 10px;
      }
    }
  }
}
</style>
