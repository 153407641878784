<template>
  <div class="listen_content">
    <div class="inner_content">
      <div class="text_center" v-if="startAnswerQues">
        <span class="game_number"
          >{{ curQIndex + 1 + usedDataLength }}/{{ allListLength }}</span
        >
      </div>
      <div class="text_content">{{ content }}</div>
      <div class="choose_question_box" v-if="startAnswerQues">
        <div
          v-for="(item, qIndex) in questionList"
          class="choose_question_item"
          :key="item.id"
        >
          <template v-if="curQIndex == qIndex">
            <a-radio-group
              @change="radioChange"
              class="radio_group display_flex flex_wrap"
            >
              <label
                class="label_item"
                v-for="itemImg in item.items"
                :key="itemImg.id"
              >
                <a-radio :value="itemImg.id">
                  <img
                    mode="widthFix"
                    class="radio_img"
                    v-if="imgUrl"
                    :src="imgUrl + itemImg.option_text"
                    srcset
                    alt
                  />
                </a-radio>
              </label>
            </a-radio-group>
          </template>
        </div>
      </div>
    </div>
    <exam-footer
      @goOnPlay="goOnPlay"
      :isNewCountDown="isNewCountDown"
      @endDown="countDownEnd"
      :source="source"
      :currentItem="currentItem"
    ></exam-footer>
    <!-- source 1是练习4 是考试 -->
  </div>
</template>

<script>
import globalConfig from '@/store/global_config';
import {
  PUBLIC_DATA,
  indexToUpper,
  showLoading,
  hideLoading
} from '@/utils/common';
import ExamFooter from '../exam-footer';
import { onlyFormatUsedData } from './index';
const { imgUrl } = PUBLIC_DATA;
export default {
  components: { ExamFooter },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    }
  },
  props: {
    itemInfo: {
      default: () => {},
      type: Object
    },
    bookId: {
      default: '',
      type: String
    },
    bookChapterId: {
      default: '',
      type: String
    },
    typeId: {
      default: '',
      type: String
    },
    gameId: {
      default: '',
      type: String
    },
    // 4是考试，1是练习
    source: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      imgUrl: imgUrl,
      // 当前问题索引
      curQIndex: 0,
      // 开始答题
      startAnswerQues: false,
      // 作业要求
      content: '',
      // 问题列表
      questionList: [],
      // 倒计时数组(包含类型等)
      countDownArr: [],
      // 倒计时索引
      countDownIndex: 0,
      // 当前题目选择的答案
      userRaidoChoose: '',
      allListLength: 0,
      usedDataLength: 0,
      isNewCountDown: false,
      playTypeObj: {},
      currentItem: {}
    };
  },

  mounted() {
    const { introduce, questions: list } = this.itemInfo;
    this.allListLength = list.length;
    this.introduce = introduce || {};
    const {
      content,
      file_url: firstClaimUrl,
      answer_time: answerTime,
      tips_time: tipsTime,
      frequency
    } = this.introduce;
    const newArr = [];
    this.content = content;
    // 如果是考试模式, 不保存进度
    let questions = onlyFormatUsedData(list);
    // 如果没有题型就触发结束事件
    if (questions.length === 0) {
      this.$emit('end');
      return;
    }
    // 本类型中的第一个题是否做过
    // 如果是考试模式第一题就当作没做过
    globalConfig.commit('changeFirstQuestion', !list[0].used);
    this.usedDataLength = list.length - questions.length;
    this.questionList = questions;
    // 第一个是要求
    newArr.push({
      type: 'audio',
      src: firstClaimUrl,
      text: '答题要求播放中',
      needPlusTime: true,
      btnType: 'jump'
    });
    questions.forEach(item => {
      const { file_url: itemAudioUrl, id } = item;
      newArr.push({
        type: 'tips',
        time: tipsTime,
        text: '请阅读相关题目',
        btnType: 'jump'
      });
      for (let i = 0; i < frequency; i++) {
        newArr.push({
          type: 'audio',
          src: itemAudioUrl,
          text: `第${indexToUpper[i]}遍`,
          btnType: 'jump'
        });
      }
      newArr.push({
        id,
        type: 'tips',
        time: answerTime,
        isEnd: true,
        text: '请选择',
        btnType: 'submit'
      });
    });
    this.countDownArr = newArr;
    this.startNextCountDown();
  },

  methods: {
    goOnPlay() {
      this.startNextCountDown();
    },

    // 失败回调函数(播放错误，等失败回调)
    errorCallback() {
      this.countDownIndex = this.countDownIndex - 1;
      this.countDownEnd();
    },

    // 单选更改
    radioChange(event) {
      this.userRaidoChoose = String(event.target.value);
    },

    startNextCountDown() {
      const countDownItem = this.countDownArr[this.countDownIndex];
      this.currentItem = { ...countDownItem };
      this.isRequesting = false;
      setTimeout(() => {
        if (this.currentItem.type === 'tips') {
          this.startAnswerQues = true;
        }
        this.isNewCountDown = !this.isNewCountDown;
      }, 0);
    },

    countDownEnd() {
      // 验证当前播放结束的那个是不是这道题目的最后一个
      const countDownItem = this.countDownArr[this.countDownIndex];
      const { isEnd, id: questionId } = countDownItem || {};
      //  倒计时结束，判断当前是不是最后一个，
      // 如果是就请求保存结果，
      // 成功后在继续播放下一个
      if (isEnd) {
        // 是这道题的最后一个，需要触发保存接口，然后才能继续下一题
        this.handleSaveUserData(questionId);
        return;
      }
      this.countDownIndex += 1;
      this.startNextCountDown();
    },

    // 保存用户数据
    handleSaveUserData(questionId) {
      const payload = {
        book_chapter_id: this.bookChapterId,
        gameId: this.gameId,
        typeId: this.typeId,
        user_answer: this.userRaidoChoose,
        question_id: questionId,
        book_id: this.bookId,
        source: this.source
      };
      if (this.isRequesting) {
        return;
      }
      this.isRequesting = true;
      showLoading();
      globalConfig
        .dispatch('submitUnitUserAnswer', payload)
        .then(() => {
          hideLoading();
          globalConfig.commit('changeFirstQuestion', false);
          this.userRaidoChoose = '';
          if (this.curQIndex + 1 >= this.questionList.length) {
            if (this.isEmitEnd) {
              return;
            }
            this.isEmitEnd = true;
            this.$emit('end');
            this.isRequesting = false;
            return;
          }
          this.curQIndex++;
          this.countDownIndex += 1;
          this.startNextCountDown();
        })
        .catch(() => {
          hideLoading();
          this.isRequesting = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../exam.less';
.label_item {
  margin-bottom: 10px;
  width: 50%;
  .radio_img {
    width: 200px;
    margin-left: 10px;
  }
}
</style>
