<template>
  <div class="content_container">
    <div class="content">
      <div class="text_center" v-if="startAnswerQues && allListLength > 1">
        <span class="question_num_title">
          {{ curtQIndex + 1 + usedListLength }}/{{ allListLength }}
        </span>
      </div>
      <div class="question_claim_content" v-if="!startAnswerQues">
        {{ content }}
      </div>
      <template v-else>
        <template v-for="(item, itemIndex) in questionList">
          <div
            v-if="curtQIndex == itemIndex"
            class="inner_question_container"
            :key="item.id"
          >
            <div>
              <div v-if="imgUrl && item.img_url">
                <img
                  class="question_item_img"
                  :src="imgUrl + item.img_url"
                  mode="widthFix"
                  alt
                />
              </div>
              <span class="font_13">{{ item.title }}</span>
            </div>
          </div>
        </template>
      </template>
    </div>
    <ExamFooter
      @endRecord="recorderEnd"
      @goOnPlay="goOnPlay"
      @endDown="countDownEnd"
      :source="source"
      :currentItem="currentItem"
      :isNewCountDown="isNewCountDown"
      :recordStatus="recorderStatus"
      :notifyReset="notifyReset"
      needRecord
    ></ExamFooter>
  </div>
</template>

<script>
import globalConfig from '@/store/global_config';
import ExamFooter from '../exam-footer';
import { PUBLIC_DATA, getStartRecordObj } from '@/utils/common';
import { onlyFormatUsedData } from './index';
import { showLoading, hideLoading } from '../../../../utils/common';
const { imgUrl, defaultRecordUrl } = PUBLIC_DATA;
export default {
  components: { ExamFooter },

  computed: {
    recorderStatus() {
      return globalConfig.state.recorderStatus;
    }
  },

  props: {
    itemInfo: {
      default: () => {},
      type: Object
    },
    bookId: {
      default: '',
      type: String
    },
    bookChapterId: {
      default: '',
      type: String
    },
    typeId: {
      default: '',
      type: String
    },
    gameId: {
      default: '',
      type: String
    },
    source: {
      default: '',
      type: String
    }
  },

  data() {
    return {
      allListLength: 0,
      // 当前问题索引
      curtQIndex: 0,
      // 做过的题目长度
      usedListLength: 0,
      // 是否开始答题
      startAnswerQues: false,
      // 倒计时索引
      countDownIndex: 0,
      // 题目要求内容
      content: '',
      // 正在请求
      requestLoading: false,
      questionList: [],
      imgUrl: imgUrl,
      currentItem: {},
      isNewCountDown: false,
      notifyReset: false
    };
  },

  mounted() {
    const { introduce, questions: list } = this.itemInfo;
    this.allListLength = list.length;
    this.introduce = introduce || {};
    const {
      content,
      file_url: firstClaimUrl,
      answer_time: answerTime,
      tips_time: tipsTime,
      audio_url: audioUrl
    } = this.introduce;
    this.content = content;
    // 如果是考试模式, 不保存进度
    let questions = onlyFormatUsedData(list);
    if (questions.length === 0) {
      this.$emit('end');
      return;
    }
    this.questionList = questions;
    this.usedListLength = list.length - questions.length;
    globalConfig.commit('changeFirstQuestion', !list[0].used);
    // 第一个是要求
    const newArr = [];
    newArr.push({
      type: 'audio',
      src: firstClaimUrl,
      text: '答题要求播放中',
      btnType: 'jump'
    });
    questions.forEach(item => {
      const { id, title: itemTitle, content: itemContent } = item;
      newArr.push({
        type: 'tips',
        time: tipsTime,
        text: '请阅读相关题目',
        btnType: 'jump'
      });
      newArr.push({
        type: 'audio',
        src: audioUrl || defaultRecordUrl,
        text: audioUrl ? '答题要求播放中' : '下面开始录音',
        btnType: 'jump',
        isFromLocal: !audioUrl
      });
      newArr.push({
        id,
        type: 'tips',
        time: answerTime,
        isEnd: true,
        text: '录音中',
        btnType: 'record',
        title: itemTitle,
        content: itemContent
      });
    });
    this.countDownArr = newArr;
    this.startNextCountDown();
  },

  methods: {
    goOnPlay() {
      this.startNextCountDown();
    },

    // 失败回调函数(播放错误，等失败回调)
    errorCallback() {
      this.countDownIndex = this.countDownIndex - 1;
      this.countDownEnd();
    },
    // 开始下一次倒计时
    startNextCountDown() {
      const countDownItem = this.countDownArr[this.countDownIndex];
      const { type, btnType } = countDownItem;
      this.btnType = btnType;
      this.notifyReset = false;
      if (type === 'tips') {
        this.startAnswerQues = true;
      }
      this.currentItem = { ...countDownItem };
      setTimeout(() => {
        this.isNewCountDown = !this.isNewCountDown;
      }, 0);
    },

    // 结束录音
    recorderEnd() {
      showLoading('评测中');
      globalConfig.commit('stopRecorder');
    },

    // 开始录音
    startStoreRecord() {
      const { score, num } = this.introduce;
      const {
        id: questionId,
        time: answerTime,
        content
      } = this.currentItemInfo;

      globalConfig.commit('startRecorder', {
        elId: 'iRecorder',
        recordParams: {
          ...getStartRecordObj('word', answerTime * 1000, content),
          rank: score / num,
          precision: 0.1
        },
        getEvalMessage: data => this.saveEvaluateData(data, questionId),
        startCallback: () => {
          hideLoading();
          this.requestLoading = true;
          this.startNextCountDown();
        },
        stopCallback: () => {
          showLoading('评测中');
        }
      });

      // globalConfig.commit('createAudio', {
      //   recordParams: {
      //     ...getStartRecordObj('word', answerTime * 1000, content),
      //     rank: score / num,
      //     precision: 0.5
      //   },

      //   startCallback: () => {
      //     this.requestLoading = true;
      //     this.startNextCountDown();
      //   },

      //   getEvalMessage: data => this.saveEvaluateData(data, questionId),

      //   evalFailCallback: () => {
      //     this.requestLoading = false;
      //     this.errorCallback();
      //   },

      //   // 当评测错误时候的回调
      //   evalStopAndErrorCallback: () => {
      //     // 通知子组件需要重置了
      //     this.notifyReset = true;
      //   }
      // });
    },

    // 接受评测结果
    saveEvaluateData(data, questionId) {
      showLoading('请稍候');
      const payload = {
        book_chapter_id: this.bookChapterId,
        gameId: this.gameId,
        typeId: this.typeId,
        question_id: questionId,
        book_id: this.bookId,
        source: this.source,
        read_result: data
      };
      globalConfig.dispatch('submitUnitUserAnswer', payload).then(() => {
        setTimeout(() => {
          hideLoading();
          globalConfig.commit('changeFirstQuestion', false);
          this.requestLoading = false;
          this.curtQIndex++;
          this.countDownEnd();
        }, 1000);
      });
    },

    countDownEnd() {
      if (this.requestLoading) {
        return;
      }
      if (this.countDownIndex + 1 >= this.countDownArr.length) {
        console.log('已经是本类型最后一个了，需要触发父组件事件');
        this.$emit('end');
        return;
      }
      // 验证当前播放结束的那个是不是这道题目的最后一个
      this.countDownIndex += 1;
      const countDownItem = this.countDownArr[this.countDownIndex];
      const { isEnd, title, time, id, content } = countDownItem;
      this.currentItemInfo = {
        id,
        title,
        time,
        content
      };
      if (isEnd) {
        this.startStoreRecord();
        return;
        // 是这道题的最后一个，需要触发保存接口，然后才能继续下一题
      }
      //  倒计时结束，判断当前是不是最后一个，
      // 如果是就请求保存结果，
      // 成功后在继续播放下一个
      this.startNextCountDown();
    }
  }
};
</script>

<style lang="less" scoped>
@import './unti.less';
.content {
  display: flex;
  flex-direction: column;
  .inner_question_container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    flex: 2;
  }
}
</style>
