<template>
  <div class="listen_content">
    <div class="inner_content">
      <div class="text_center" v-if="startAns && allLength > 1">
        <span class="game_number">
          {{ curtQIndex + 1 + usedListLength }}/{{ allLength }}
        </span>
      </div>
      <div class="text_content">{{ content }}</div>
      <template v-if="startAns">
        <div
          v-for="(item, itemIndex) in questionList"
          :key="item.id"
          class="choose_question_box"
        >
          <div v-if="curtQIndex == itemIndex" class="choose_question_item ">
            <div v-if="item.title" class="question_title">{{ item.title }}</div>
            <a-radio-group class="radio_group flex_wrap" @change="radioChange">
              <label
                class="label_item full_width"
                v-for="(itemImg, itemImgIndex) in item.items"
                :key="itemImg.id"
              >
                <a-radio
                  :color="themeColor"
                  class="label_radio"
                  :value="itemImg.id"
                ></a-radio>
                <div>{{ indexPrefix[itemImgIndex] }}:</div>
                <span class="radio_text">{{ itemImg.option_text }}</span>
              </label>
            </a-radio-group>
          </div>
        </div>
      </template>
    </div>
    <ExamFooter
      :currentItem="currentItem"
      :source="source"
      @endDown="countDownEnd"
      @goOnPlay="goOnPlay"
      :isNewCountDown="isNewCountDown"
    ></ExamFooter>
  </div>
</template>

<script>
import globalConfig from '@/store/global_config';
import ExamFooter from '../exam-footer';
import {
  PUBLIC_DATA,
  indexPrefix,
  indexToUpper,
  showLoading,
  hideLoading
} from '@/utils/common';
import { onlyFormatUsedData } from './index';
const { imgUrl } = PUBLIC_DATA;
export default {
  components: { ExamFooter },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    isFirstQuestion() {
      return globalConfig.state.isFirstQuestion;
    },
    isFirstGame() {
      return globalConfig.state.isFirstGame;
    }
  },
  props: {
    itemInfo: {
      default: () => {},
      type: Object
    },
    bookId: {
      default: '',
      type: String
    },
    bookChapterId: {
      default: '',
      type: String
    },
    typeId: {
      default: '',
      type: String
    },
    source: {
      default: '',
      type: String
    },
    gameId: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      indexPrefix: indexPrefix,
      imgUrl: imgUrl,
      // 当前问题索引
      curtQIndex: 0,
      // 开始答题
      startAns: false,
      // 作业要求
      content: '',
      // 问题列表
      questionList: [],
      // 倒计时数组(包含类型等)
      countDownArr: [],
      // 倒计时索引
      countDownIndex: 0,
      // 当前题目选择的答案
      userRaidoChoose: '',
      // 总共的小题数量
      allLength: 0,
      // 做过的小题数
      usedListLength: 0,
      currentItem: {},
      isNewCountDown: false
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      const { introduce, questions: list } = this.itemInfo;
      this.allLength = list.length;
      this.introduce = introduce || {};
      const {
        content,
        file_url: firstClaimUrl,
        answer_time: answerTime,
        tips_time: tipsTime,
        frequency
      } = this.introduce;
      const newArr = [];
      this.content = content;
      // 是否是考试模式
      const isExamType = Number(this.source) === 4;
      // 如果是考试模式, 不保存进度
      let questions = isExamType ? list : onlyFormatUsedData(list);
      if (questions.length === 0) {
        this.$emit('end');
        return;
      }
      this.questionList = questions;
      this.usedListLength = list.length - questions.length;
      // 是否是本题型的第一个题
      globalConfig.commit('changeFirstQuestion', isExamType || !list[0].used);
      // 第一个是要求
      newArr.push({
        type: 'audio',
        src: firstClaimUrl,
        text: '答题要求播放中',
        needPlusTime: true,
        btnType: 'jump'
      });
      questions.forEach(item => {
        newArr.push({
          type: 'tips',
          time: tipsTime,
          text: '请阅读相关题目',
          btnType: 'jump'
        });
        const { file_url: itemAudioUrl, id } = item;
        for (let i = 0; i < frequency; i++) {
          newArr.push({
            type: 'audio',
            src: itemAudioUrl,
            text: `第${indexToUpper[i]}遍`,
            btnType: 'jump'
          });
        }
        newArr.push({
          id,
          type: 'tips',
          time: answerTime,
          isEnd: true,
          text: '请选择',
          btnType: 'submit'
        });
      });
      this.countDownArr = newArr;
      this.startNextCountDown();
    },

    goOnPlay() {
      this.startNextCountDown();
    },
    // 失败回调函数(播放错误，等失败回调)
    errorCallback() {
      this.countDownIndex = this.countDownIndex - 1;
      this.countDownEnd();
    },

    // 单选更改
    radioChange(event) {
      this.userRaidoChoose = String(event.target.value);
    },

    startNextCountDown() {
      if (this.countDownIndex >= this.countDownArr.length) {
        if (this.isEmitEnd) {
          return;
        }
        this.isEmitEnd = true;
        this.$emit('end');
        return;
      }
      const countDownItem = this.countDownArr[this.countDownIndex];
      this.currentItem = { ...countDownItem };
      setTimeout(() => {
        if (countDownItem.type === 'tips') {
          this.startAns = true;
        }
        this.isNewCountDown = !this.isNewCountDown;
      }, 0);
    },

    countDownEnd() {
      // 验证当前播放结束的那个是不是这道题目的最后一个
      const countDownItem = this.countDownArr[this.countDownIndex];
      const { isEnd, id: questionId } = countDownItem || {};
      this.countDownIndex += 1;
      //  倒计时结束，判断当前是不是最后一个，
      // 如果是就请求保存结果，
      // 成功后在继续播放下一个
      if (isEnd) {
        // 是这道题的最后一个，需要触发保存接口，然后才能继续下一题
        this.handleSaveUserData(questionId);
        return;
      }
      this.startNextCountDown();
    },

    // 保存用户数据
    handleSaveUserData(questionId) {
      const payload = {
        book_chapter_id: this.bookChapterId,
        gameId: this.gameId,
        typeId: this.typeId,
        user_answer: this.userRaidoChoose,
        question_id: questionId,
        book_id: this.bookId,
        source: this.source
      };
      if (this.isRequesting) {
        return;
      }
      this.isRequesting = true;
      showLoading();
      globalConfig
        .dispatch('submitUnitUserAnswer', payload)
        .then(() => {
          hideLoading();
          globalConfig.commit('changeFirstQuestion', false);
          this.curtQIndex += 1;
          this.startNextCountDown();
          this.userRaidoChoose = '';
          this.isRequesting = false;
        })
        .catch(() => {
          hideLoading();
          this.isRequesting = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../exam.less';
</style>
