<template>
  <div class="outer_page">
    <my-bread-crumb :breadMenu="breadMenu"></my-bread-crumb>
    <div class="inner_content">
      <div v-if="!showLastPage" class="exam_content">
        <div class="exam_left">
          <a-menu
            v-for="leftItem in allGameList"
            :key="'' + leftItem.id"
            :selectedKeys="[gameId]"
          >
            <a-menu-item :key="'' + leftItem.id">
              {{ leftItem.name }}
            </a-menu-item>
          </a-menu>
        </div>
        <div class="exam_right">
          <div class="exam_game_title">
            <span>{{ nameList[nameTitleIndex] }}</span>
            <span class="right_toggle" @click="toggleSourceType">
              <a-button type="link">
                <span class="anticon-qiehuan anticon-yyashow"></span>
                {{ examTextObj[sourceId] }}
              </a-button>
            </span>
          </div>
          <div class="content_container">
            <!-- 听力选图 -->
            <listenChoose-pic
              @end="startNextType"
              :itemInfo="taskInfo"
              :bookId="bookId"
              :bookChapterId="bookChapterId"
              :typeId="typeId"
              :gameId="gameId"
              :source="sourceId"
              v-if="templatePath === '/pages/discover/listen-choose-pic/main'"
            ></listenChoose-pic>
            <!-- 听力选答 -->
            <listen-choose-answer
              @end="startNextType"
              :itemInfo="taskInfo"
              :bookId="bookId"
              :bookChapterId="bookChapterId"
              :typeId="typeId"
              :gameId="gameId"
              :source="sourceId"
              v-if="templatePath === '/pages/discover/listen-choose-answe/main'"
            ></listen-choose-answer>
            <!-- 听对话选择 -->
            <choose-dialog
              @end="startNextType"
              :itemInfo="taskInfo"
              :bookId="bookId"
              :bookChapterId="bookChapterId"
              :typeId="typeId"
              :gameId="gameId"
              :source="sourceId"
              v-if="
                templatePath === '/pages/discover/choose-dialog-answer/main'
              "
            ></choose-dialog>
            <!-- 听后记录 -->
            <listen-fill-blank
              @end="startNextType"
              :itemInfo="taskInfo"
              :bookId="bookId"
              :bookChapterId="bookChapterId"
              :typeId="typeId"
              :gameId="gameId"
              :source="sourceId"
              v-if="templatePath === '/pages/discover/listen-fill-blank/main'"
            ></listen-fill-blank>
            <new-fill-blank
              @end="startNextType"
              :itemInfo="taskInfo"
              :bookId="bookId"
              :bookChapterId="bookChapterId"
              :typeId="typeId"
              :gameId="gameId"
              :source="sourceId"
              v-if="templatePath === 'new-fill-blank'"
            >
            </new-fill-blank>

            <!-- 听后选择 -->
            <after-listen-choose
              @end="startNextType"
              :itemInfo="taskInfo"
              :bookId="bookId"
              :bookChapterId="bookChapterId"
              :typeId="typeId"
              :gameId="gameId"
              :source="sourceId"
              v-if="templatePath === '/pages/discover/after-listen/main'"
            ></after-listen-choose>
            <!-- 课文跟读 -->
            <text-speak
              @end="startNextType"
              :itemInfo="taskInfo"
              :bookId="bookId"
              :bookChapterId="bookChapterId"
              :typeId="typeId"
              :gameId="gameId"
              :source="sourceId"
              v-if="templatePath === '/pages/discover/unit-text-speak/main'"
            ></text-speak>
            <!-- 情景问答 -->
            <scene-question
              @end="startNextType"
              :itemInfo="taskInfo"
              :bookId="bookId"
              :bookChapterId="bookChapterId"
              :typeId="typeId"
              :gameId="gameId"
              :source="sourceId"
              v-if="
                templatePath === '/pages/discover/scene-question-answer/main'
              "
            ></scene-question>
            <topic-exposition
              :itemInfo="taskInfo"
              @end="startNextType"
              :bookId="bookId"
              :bookChapterId="bookChapterId"
              :typeId="typeId"
              :gameId="gameId"
              :source="sourceId"
              v-if="templatePath === '/pages/discover/topic-exposition/main'"
            ></topic-exposition>
            <!-- 听后回答 -->
            <listen-record
              :itemInfo="taskInfo"
              @end="startNextType"
              :bookId="bookId"
              :bookChapterId="bookChapterId"
              :typeId="typeId"
              :gameId="gameId"
              :source="sourceId"
              v-if="templatePath === '/pages/discover/listen-answer/main'"
            ></listen-record>
            <!-- 听后回答(带阅读题目) -->
            <listen-speak
              :itemInfo="taskInfo"
              @end="startNextType"
              :bookId="bookId"
              :bookChapterId="bookChapterId"
              :typeId="typeId"
              :gameId="gameId"
              :source="sourceId"
              v-if="templatePath === '/pages/discover/listen-speak/main'"
            ></listen-speak>
            <!-- 听力叙述 -->
            <listen-report
              :itemInfo="taskInfo"
              @end="startNextType"
              :bookId="bookId"
              :bookChapterId="bookChapterId"
              :typeId="typeId"
              :gameId="gameId"
              :source="sourceId"
              :fillId="fillId"
              v-if="templatePath === '/pages/discover/listen-report/main'"
            ></listen-report>
            <!-- 识读音标 -->
            <identify-symbol
              v-if="templatePath === '/pages/discover/identify-symbol/main'"
              @end="startNextType"
              :bookId="bookId"
              :bookChapterId="bookChapterId"
              :typeId="typeId"
              :gameId="gameId"
              :source="sourceId"
              :itemInfo="taskInfo"
            ></identify-symbol>
            <!-- 听短对话回答多个问题 -->
            <Listen-essay
              v-if="templatePath === 'listen-essay-answer'"
              @end="startNextType"
              :bookId="bookId"
              :bookChapterId="bookChapterId"
              :typeId="typeId"
              :gameId="gameId"
              :source="sourceId"
              :itemInfo="taskInfo"
            ></Listen-essay>
          </div>
        </div>
      </div>
      <last-page
        v-else
        :source="sourceId"
        :bookId="bookId"
        :chapterId="bookChapterId"
        :typeId="typeId"
      ></last-page>
    </div>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue';
import MyBreadCrumb from '../../components/BreadCrumb';
import {
  showMyToast,
  testAutoPlay,
  indexToUpper,
  showMyModal
} from '@/utils/common';
import globalConfig from '@/store/global_config';
import { fetchBookGameList, getQuestionInfo } from '@/api/service';
import LastPage from './last-page';
import ListenChoosePic from './components/unitPractise/listen-choose-pic';
import ListenChooseAnswer from './components/unitPractise/listen-choose-answer';
import ChooseDialog from './components/unitPractise/listen-dialog';
import ListenFillBlank from './components/unitPractise/listen-fill-blank';
import NewFillBlank from './components/unitPractise/new-fill-blank';
import TextSpeak from './components/unitPractise/text-speak';
import SceneQuestion from './components/unitPractise/scene-question';
import TopicExposition from './components/unitPractise/topic-exposition';
import AfterListenChoose from './components/unitPractise/after-listen-choose';
import ListenRecord from './components/unitPractise/listen-record';
import ListenReport from './components/unitPractise/listen-report';
import IdentifySymbol from './components/unitPractise/identify-symbol';
import ListenEssay from './components/unitPractise/listen-essay';
import ListenSpeak from './components/unitPractise/listen-speak.vue';
export default {
  inject: ['reload'],

  components: {
    LastPage,
    ListenChoosePic,
    ListenChooseAnswer,
    ChooseDialog,
    ListenFillBlank,
    TextSpeak,
    SceneQuestion,
    TopicExposition,
    AfterListenChoose,
    ListenReport,
    ListenRecord,
    IdentifySymbol,
    ListenEssay,
    MyBreadCrumb,
    NewFillBlank,
    ListenSpeak
  },

  created() {
    const { bookId, chapterId, sourceId, typeId } = this.$route.params;
    this.bookId = bookId;
    this.bookChapterId = chapterId;
    this.sourceId = sourceId;
    this.typeId = typeId;
    this.fetchExamList();
    this.breadMenu = [
      { title: '选择教材', path: '/book' },
      { title: '教材详情', path: `/book/${this.bookId}` },
      { title: '学习详情' }
    ];
  },
  data() {
    return {
      allGameList: [],
      gameList: [],
      usedListLength: 0,
      nameTitleIndex: 0,
      // 当前游戏的索引
      currentGameIndex: 0,
      nameList: [],
      // 当前题型
      templatePath: '',
      templateGameId: '',
      bookId: '',
      bookChapterId: '',
      sourceId: '',
      typeId: '',
      taskInfo: '',
      gameId: '',
      loaidngStatus: true,
      // 显示已经完成
      showLastPage: false,
      examTextObj: {
        1: '练习模式',
        4: '考试模式'
      },
      breadMenu: []
    };
  },

  beforeDestroy() {
    globalConfig.commit('resetRecorder');
    globalConfig.commit('stopAudio');
  },

  methods: {
    // 切换考试模式
    toggleSourceType() {
      const toggleTypeId = {
        1: 4,
        4: 1
      };
      showMyModal('确定要切换吗？', () => {
        // 替换当前路由并进行切换刷新
        this.$router.replace(
          `/exam/${this.bookId}/${this.bookChapterId}/${this.typeId}/${
            toggleTypeId[this.sourceId]
          }`
        );
        this.reload();
      });
    },

    fetchExamList() {
      fetchBookGameList({
        bookId: this.bookId,
        bookChapterId: this.bookChapterId,
        type: this.typeId,
        source: this.sourceId
      }).then(response => {
        const listData = response.data;
        if (listData.length === 0) {
          showMyToast('w', '暂时还没有发布内容');
          setTimeout(() => {
            this.$router.push('/book');
          });
          return;
        }
        this.nameList = this.formatNameList(listData);
        this.allGameList = listData;
        const gameList = this.getUnfinishedList(listData);
        if (gameList.length === 0) {
          this.showLastPage = true;
          return;
        }
        this.gameList = gameList;

        const isFirstGame = gameList.length === listData.length;
        globalConfig.commit('changeFirstGame', isFirstGame);
        // globalConfig.commit('initRecorder', {
        //   micFailCallback: () => {
        //     showMyHint('请先打开录音权限，再重试', () => {
        //       window.history.back();
        //     });
        //   },
        //   micSuccessCallback: () => {
        //     this.checkAutoPlay();
        //   }
        // });
        this.checkAutoPlay();
      });
    },

    checkAutoPlay() {
      testAutoPlay().then(autoplay => {
        // 如果不能自动播放
        if (!autoplay) {
          Modal.confirm({
            centered: true,
            destroyOnClose: false,
            content: '开始考试',
            cancelText: '取消',
            okText: '确定',
            onOk: () => {
              this.setCurrentTemplate();
            },
            onCancel: () => {
              this.$router.back(-1);
            }
          });
          return;
        }
        // 如果可以自动播放
        this.setCurrentTemplate();
      });
    },

    // 设置当前题目类型
    setCurrentTemplate() {
      const currentItem = this.gameList[this.currentGameIndex];
      const { template_path: templatePath, id } = currentItem;
      this.gameId = String(id);
      this.fetchCurrentGameInfo(templatePath);
    },

    fetchCurrentGameInfo(templatePath) {
      getQuestionInfo({
        bookId: this.bookId,
        bookChapterId: this.bookChapterId,
        typeId: this.typeId,
        gameId: this.gameId,
        source: this.sourceId
      }).then(response => {
        this.taskInfo = response.data;
        this.templatePath = templatePath.trim();
      });
    },

    // 获得namelist
    formatNameList(arr) {
      const newArr = [];
      let index = -1;
      let isPlus = false;
      let isShowGameOrder = localStorage.getItem(
        'is_show_game_order_' + this.bookId
      );
      arr.forEach(item => {
        if (item.is_relation) {
          if (!isPlus) {
            index += 1;
          }
          isPlus = true;
          if (isShowGameOrder > 0) {
            newArr.push(`${indexToUpper[index]}、${item.alias_name}`);
          } else {
            newArr.push(`${item.alias_name}`);
          }
        } else {
          index += 1;
          if (isShowGameOrder > 0) {
            newArr.push(`${indexToUpper[index]}、${item.name}`);
          } else {
            newArr.push(`${item.name}`);
          }
        }
      });
      return newArr;
    },

    // 获得没有做完的游戏列表
    getUnfinishedList(listData) {
      const newArr = [];
      // 是否是第一次
      let isFirst = false;
      listData.forEach(listItem => {
        const { rate, is_relation: isRelation, id } = listItem;
        if (!isFirst && Number(isRelation) === 1) {
          this.fillId = id;
          isFirst = true;
        }
        if (Number(rate) < 1) {
          newArr.push(listItem);
        } else {
          this.nameTitleIndex += 1;
        }
      });
      return newArr;
    },

    startNextType() {
      globalConfig.commit('changeFirstGame', false);
      if (this.currentGameIndex + 1 >= this.gameList.length) {
        this.showLastPage = true;
        return;
      }
      this.templatePath = '';
      this.nameTitleIndex += 1;
      this.currentGameIndex++;
      this.setCurrentTemplate();
    }
  }
};
</script>

<style lang="less" scoped>
.outer_page {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  .inner_content {
    flex: 2;
    position: relative;
  }
}
.exam_content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  .exam_left {
    width: 30%;
    height: 100%;
    overflow-y: scroll;
    background: white;
    border-right: solid 1px rgb(232, 232, 232);
    .ant-menu {
      border: none;
    }
  }
  .exam_right {
    flex: 2;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    display: flex;
    flex-direction: column;
    background: white;
    padding-top: 20px;
    .exam_game_title {
      text-align: center;
      font-size: 1.25rem;
      font-weight: bold;
      .right_toggle {
        position: absolute;
        right: 20px;
        font-weight: lighter;
        font-size: 0.9rem;
        .anticon-yyashow {
          font-size: 14px;
        }
      }
    }
    .content_container {
      flex: 2;
      position: relative;
    }
  }
}
</style>
