<template>
  <div class="listen_content">
    <div class="inner_content">
      <div class="text_center" v-if="startAnswerQues && allListLength > 1">
        <span class="game_number">
          {{ currentQIndex + 1 + usedListLength }}/{{ allListLength }}
        </span>
      </div>
      <div class="text_content" v-if="!startAnswerQues">
        {{ content }}
      </div>
      <div v-else class="blank_content_box">
        <template v-for="(item, itemIndex) in questionList">
          <div
            v-if="currentQIndex == itemIndex"
            class="blank_content_item"
            :key="item.id"
          >
            <text class="audio_content" v-if="innerArrIndex >= 0">
              {{ audioContent }}
            </text>
            <template v-if="innerArrIndex >= 1">
              <div class="img_box" @click="showMask(item.file_url)">
                <img
                  class="file_url"
                  mode="widthFix"
                  v-if="imgUrl"
                  :src="imgUrl + item.file_url"
                  alt
                />
                <div class="right_btn">点击查看大图</div>
              </div>
            </template>
            <template v-if="showInput">
              <div class="tip_title" v-if="tipTitle">{{ tipTitle }}</div>
              <div>请在以下区域作答：</div>
              <div
                v-for="(inputItem, inputIndex) in item.link"
                class="blank_input_box"
                :key="inputItem.id"
              >
                <span class="blank_item_left">{{ inputIndex + 1 }}.</span>
                <input
                  class="blank_item_input"
                  placeholder="请在此输入相应的答案"
                  @input="
                    inputChange($event, inputItem.question_id, inputIndex)
                  "
                />
              </div>
            </template>
          </div>
        </template>
      </div>
      <div v-if="showMaskImg" class="mask_outer" @click="hideMask">
        <img
          class="img_url"
          mode="widthFix"
          v-if="imgUrl"
          :src="imgUrl + maskImg"
          alt
        />
      </div>
    </div>
    <ExamFooter
      :source="source"
      @goOnPlay="goOnPlay"
      @endDown="countDownEnd"
      :currentItem="currentItem"
      :isNewCountDown="isNewCountDown"
    ></ExamFooter>
  </div>
</template>

<script>
import globalConfig from '@/store/global_config';
import {
  PUBLIC_DATA,
  indexToUpper,
  showLoading,
  hideLoading
} from '@/utils/common';
import { onlyFormatUsedData } from './index';
import ExamFooter from '../exam-footer';
const { imgUrl } = PUBLIC_DATA;
export default {
  components: { ExamFooter },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    }
  },
  props: {
    itemInfo: {
      default: () => {},
      type: Object
    },
    bookId: {
      default: '',
      type: String
    },
    bookChapterId: {
      default: '',
      type: String
    },
    typeId: {
      default: '',
      type: String
    },
    gameId: {
      default: '',
      type: String
    },
    source: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      imgUrl: imgUrl,
      // 当前问题索引
      currentQIndex: 0,
      // 播放状态
      playStatus: false,
      // 倒计时文本
      countDownText: '',
      // 开始答题
      startAnswerQues: false,
      // 作业要求
      content: '',
      // 问题列表
      questionList: [],
      // 当前问题内部倒计时数组的索引值
      innerArrIndex: 0,
      // 倒计时数组(包含类型等)
      countDownArr: [],
      // 倒计时索引
      countDownIndex: 0,
      // 用户输入的答案
      userInputValue: [],
      showMaskImg: false,
      maskImg: '',
      // 总共的小题数量
      allListLength: 0,
      // 做过的小题数
      usedListLength: 0,
      // 题目的要求
      audioContent: '',
      tipTitle: '',
      isNewCountDown: false,
      currentItem: {},
      showInput: false
    };
  },

  mounted() {
    const { introduce, questions: list } = this.itemInfo;
    this.allListLength = list.length;
    this.introduce = introduce || {};
    const {
      frequency,
      content,
      file_url: firstClaimUrl,
      answer_time: answerTime,
      tips_time: tipsTime,
      audio_url: audioUrl,
      audio_content: audioContent
    } = this.introduce;
    const newArr = [];
    this.content = content;
    // 如果是考试模式, 不保存进度
    let questions = onlyFormatUsedData(list);
    if (questions.length === 0) {
      this.$emit('end');
      return;
    }
    this.questionList = questions;
    this.usedListLength = list.length - questions.length;
    // 是否是本类型的第一个题，第一个题有没有做过。
    globalConfig.commit('changeFirstQuestion', !list[0].link[0].question.used);
    // 第一个是要求
    newArr.push({
      type: 'audio',
      src: firstClaimUrl,
      text: '答题要求播放中',
      needPlusTime: true,
      btnType: 'jump'
    });
    questions.forEach(item => {
      const {
        id,
        audio_url: itemAudioUrl,
        tip_title: tipTitle,
        tip_url: tipUrl
      } = item;
      // 如果答题要求和答题音频存在就push
      if (audioContent && audioUrl) {
        newArr.push({
          type: 'audio',
          text: '答题要求播放中',
          src: audioUrl,
          startAnswerQues: true,
          btnType: 'jump',
          audioContent
        });
      }
      newArr.push({
        type: 'tips',
        time: tipsTime,
        text: '请阅读相关题目',
        btnType: 'jump',
        startAnswerQues: true
      });
      for (let i = 0; i < frequency; i++) {
        newArr.push({
          type: 'audio',
          src: itemAudioUrl,
          text: `第${indexToUpper[i]}遍`,
          btnType: 'jump',
          showInput: true
        });
      }
      if (tipUrl && tipTitle) {
        newArr.push({
          type: 'audio',
          tipTitle: tipTitle,
          src: tipUrl,
          text: '答题要求播放中',
          btnType: 'jump'
        });
      }
      // 答题前的要求
      newArr.push({
        id,
        tipTitle: tipTitle,
        type: 'tips',
        time: answerTime,
        isEnd: true,
        text: '请输入答案',
        btnType: 'submit'
      });
    });
    this.countDownArr = newArr;
    this.startNextCountDown();
  },

  methods: {
    goOnPlay() {
      this.startNextCountDown();
    },

    showMask(imgUrl) {
      this.showMaskImg = true;
      this.maskImg = imgUrl;
    },

    hideMask() {
      this.showMaskImg = false;
      this.maskImg = '';
    },

    inputChange(event, parentId, outerIndex) {
      const targetValue = String(event.target.value);
      const newArr = [...this.userInputValue];
      // 是否已经存在了
      let isFlag = false;
      newArr.forEach((item, itemIndex) => {
        if (item && item.id === parentId) {
          isFlag = true;
          newArr[itemIndex] = {
            id: String(parentId),
            user_answer: targetValue
          };
        }
      });
      if (!isFlag) {
        newArr[outerIndex] = {
          id: String(parentId),
          user_answer: targetValue
        };
      }
      this.userInputValue = [...newArr];
    },

    startNextCountDown() {
      const countDownItem = this.countDownArr[this.countDownIndex];
      const {
        startAnswerQues,
        tipTitle,
        audioContent,
        showInput
      } = countDownItem;
      if (startAnswerQues) {
        this.startAnswerQues = true;
      }
      this.isRequesting = false;
      this.showInput = this.showInput || showInput;
      this.tipTitle = this.tipTitle || tipTitle;
      this.audioContent = this.audioContent || audioContent;
      this.currentItem = { ...countDownItem };
      setTimeout(() => {
        this.isNewCountDown = !this.isNewCountDown;
      }, 0);
    },

    countDownEnd() {
      this.hideMask();
      // 验证当前播放结束的那个是不是这道题目的最后一个
      const countDownItem = this.countDownArr[this.countDownIndex];
      const { isEnd, id: questionId } = countDownItem || {};
      this.innerArrIndex += 1;
      //  倒计时结束，判断当前是不是最后一个，
      // 如果是就请求保存结果，
      // 成功后在继续播放下一个
      if (isEnd) {
        // 是这道题的最后一个，需要触发保存接口，然后才能继续下一题
        this.handleSaveUserData(questionId);
        return;
      }
      this.countDownIndex += 1;
      this.startNextCountDown();
    },

    // 保存用户数据
    handleSaveUserData(questionId) {
      this.playStatus = false;
      const currentLinkArr = this.questionList[this.currentQIndex];
      let newArr = [];
      currentLinkArr.link.forEach((itemLink, linkIndex) => {
        const { question } = itemLink;
        const { id } = question;
        if (this.userInputValue[linkIndex]) {
          newArr[linkIndex] = {
            ...this.userInputValue[linkIndex]
          };
        } else {
          newArr[linkIndex] = {
            id: String(id),
            user_answer: ''
          };
        }
      });
      const payload = {
        book_chapter_id: this.bookChapterId,
        gameId: this.gameId,
        typeId: this.typeId,
        question: newArr,
        question_id: questionId,
        book_id: this.bookId,
        source: this.source
      };
      if (this.isRequesting) {
        return;
      }
      this.isRequesting = true;
      showLoading();
      globalConfig
        .dispatch('submitUnitUserAnswer', payload)
        .then(() => {
          globalConfig.commit('changeFirstQuestion', false);
          this.innerArrIndex = 0;
          hideLoading();
          if (this.currentQIndex + 1 >= this.questionList.length) {
            if (this.isEmitEnd) {
              return;
            }
            this.isRequesting = false;
            this.isEmitEnd = true;
            console.log('已经是本类型最后一个了，需要触发父组件事件');
            this.$emit('end');
            return;
          }
          this.currentQIndex++;
          this.tipTitle = '';
          this.showInput = false;
          this.audioContent = '';
          this.countDownIndex += 1;
          this.userInputValue = [];
          this.startNextCountDown();
        })
        .catch(() => {
          hideLoading();
          this.isRequesting = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../exam.less';
.mask_outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 10000;
  display: flex;
  align-items: center;
  .img_url {
    width: 100%;
  }
}
</style>
