//  过滤已经做完的、已经删除的
export function formatUsedData(list) {
  const newArr = [];
  const onlyFilterNull = [];
  list.forEach(item => {
    const { link } = item;
    const newLinkArr = [];
    const filterNull = [];
    link.forEach(innerLink => {
      const { question: linkQuestion } = innerLink;
      // 后台删除question子项目后question这项为null
      if (linkQuestion) {
        filterNull.push(innerLink);
        // 在判断子项有没有回答过
        if (!linkQuestion.used) {
          newLinkArr.push(innerLink);
        }
      }
    });
    onlyFilterNull.push({
      ...item,
      link: filterNull
    });
    if (newLinkArr.length > 0) {
      newArr.push({
        ...item,
        link: newLinkArr
      });
    }
  });
  return { newArr, onlyFilterNull };
}

export function onlyFormatUsedData(list) {
  const newArr = [];
  list.forEach(item => {
    if (!item.used) {
      newArr.push({ ...item });
    }
  });
  return newArr;
}
