<template>
  <div class="listen_content">
    <div class="content">
      <div class="title" v-if="startAnswerQues && questionList > 1">
        {{ curtQIndex + 1 + usedLength }}/{{ allListLength }}
      </div>
      <div class="text_content" v-if="!startAnswerQues">
        {{ content }}
      </div>
      <div v-else>
        <template v-for="(item, itemIndex) in questionList">
          <div
            v-if="curtQIndex == itemIndex"
            class="outer_question"
            :key="item.id"
          >
            <pre>{{ item.title }}</pre>
          </div>
        </template>
      </div>
    </div>
    <ExamFooter
      @endRecord="recorderEnd"
      @goOnPlay="goOnPlay"
      @endDown="countDownEnd"
      :source="source"
      :currentItem="currentItem"
      :isNewCountDown="isNewCountDown"
      :recordStatus="recorderStatus"
      :notifyReset="notifyReset"
      needRecord
    ></ExamFooter>
  </div>
</template>

<script>
import ExamFooter from '../exam-footer';
import globalConfig from '@/store/global_config';
import { PUBLIC_DATA, indexToUpper } from '@/utils/common';
import { onlyFormatUsedData } from './index';
import { showLoading, hideLoading } from '../../../../utils/common';
const { imgUrl, defaultRecordUrl } = PUBLIC_DATA;

export default {
  components: { ExamFooter },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    recorderStatus() {
      return globalConfig.state.recorderStatus;
    }
  },
  props: {
    itemInfo: {
      default: () => {},
      type: Object
    },
    bookId: {
      default: '',
      type: String
    },
    bookChapterId: {
      default: '',
      type: String
    },
    typeId: {
      default: '',
      type: String
    },
    gameId: {
      default: '',
      type: String
    },
    source: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      imgUrl: imgUrl,
      // 当前问题索引
      curtQIndex: 0,
      // 开始答题
      startAnswerQues: false,
      // 作业要求
      content: '',
      // 问题列表
      questionList: [],
      // 倒计时数组(包含类型等)
      countDownArr: [],
      // 倒计时索引
      countDownIndex: 0,
      // 当前录音对象包含的信息
      currentItemInfo: {},
      requestLoading: false,
      // 总共的小题数量
      allListLength: 0,
      // 做过的小题数
      usedLength: 0,
      isNewCountDown: false,
      currentItem: {},
      notifyReset: false
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      const { introduce, questions: list } = this.itemInfo;
      this.allListLength = list.length;
      this.introduce = introduce || {};
      const {
        content,
        file_url: firstClaimUrl,
        audio_url: audioUrl,
        answer_time: answerTime,
        tips_time: tipsTime,
        frequency
      } = this.introduce;
      this.content = content;
      // 如果是考试模式, 不保存进度
      let questions = onlyFormatUsedData(list);
      if (questions.length <= 0) {
        this.$emit('end');
        return;
      }
      this.usedLength = list.length - questions.length;
      globalConfig.commit('changeFirstQuestion', !list[0].used);
      const newArr = [];
      this.questionList = questions;
      // 第一个是要求
      newArr.push({
        type: 'audio',
        src: firstClaimUrl,
        text: '答题要求播放中',
        needPlusTime: true,
        btnType: 'jump'
      });
      questions.forEach(item => {
        const { id, file_url: itemAudioUrl } = item;
        newArr.push({
          type: 'tips',
          time: tipsTime,
          text: '请阅读相关题目',
          btnType: 'jump'
        });
        if (itemAudioUrl) {
          for (let i = 0; i < frequency; i++) {
            newArr.push({
              type: 'audio',
              src: itemAudioUrl,
              text: `第${indexToUpper[i]}遍`,
              btnType: 'jump'
            });
          }
        }
        newArr.push({
          type: 'audio',
          src: audioUrl || defaultRecordUrl,
          text: audioUrl ? '答题要求播放中' : '下面开始录音',
          btnType: 'jump',
          isFromLocal: !audioUrl
        });
        newArr.push({
          answer: JSON.parse(item.answer),
          id,
          type: 'tips',
          time: answerTime,
          isEnd: true,
          text: '录音中',
          title: item.title,
          btnType: 'record'
        });
      });
      this.countDownArr = newArr;
      this.startNextCountDown();
    },

    goOnPlay() {
      this.startNextCountDown();
    },

    // 失败回调函数(播放错误，等失败回调)
    errorCallback() {
      this.countDownIndex = this.countDownIndex - 1;
      this.countDownEnd();
    },

    startNextCountDown() {
      const countDownItem = this.countDownArr[this.countDownIndex];
      const { type, btnType } = countDownItem;
      this.btnType = btnType;
      if (type === 'tips') {
        this.startAnswerQues = true;
      }
      this.notifyReset = false;
      this.currentItem = { ...countDownItem };
      setTimeout(() => {
        this.isNewCountDown = !this.isNewCountDown;
      }, 0);
    },

    formatAnswerArr(itemArr) {
      const newArr = [];
      itemArr.forEach(item => {
        newArr.push({
          text: item
        });
      });
      return newArr;
    },

    startRecord() {
      const { time, answer, id: questionId } = this.currentItemInfo;
      const { score, num } = this.introduce;

      const newObj = {
        lm: this.formatAnswerArr(answer),
        coreType: 'en.retell.score',
        evalTime: time * 1000,
        attachAudioUrl: 1,
        phdet: 1,
        syllable: 1,
        syldet: 1,
        symbol: 1,
        precision: 0.1,
        rank: score / num
      };
      globalConfig.commit('startRecorder', {
        elId: 'iRecorder',
        recordParams: newObj,
        startCallback: () => {
          this.requestLoading = true;
          this.startNextCountDown();
        },
        getEvalMessage: data => this.handleSaveRecordData(data, questionId),
        stopCallback: () => {
          showLoading('评测中');
        },
        evalFailCallback: () => {
          this.requestLoading = false;
          this.errorCallback();
        }
      });
    },

    // 停止录音
    recorderEnd() {
      globalConfig.commit('stopRecorder');
    },

    handleSaveRecordData(data, questionId) {
      showLoading('请稍候');
      const payload = {
        book_chapter_id: this.bookChapterId,
        gameId: this.gameId,
        typeId: this.typeId,
        question_id: questionId,
        book_id: this.bookId,
        source: this.source,
        read_result: data
      };
      globalConfig.dispatch('submitUnitUserAnswer', payload).then(() => {
        setTimeout(() => {
          hideLoading();
          globalConfig.commit('changeFirstQuestion', false);
          this.curtQIndex++;
          this.requestLoading = false;
          this.countDownEnd();
        }, 1000);
      });
    },

    countDownEnd() {
      if (this.requestLoading) {
        return;
      }
      if (this.countDownIndex + 1 >= this.countDownArr.length) {
        console.log('已经是本类型最后一个了，需要触发父组件事件');
        this.$emit('end');
        return;
      }
      showLoading();
      // 验证当前播放结束的那个是不是这道题目的最后一个
      this.countDownIndex += 1;
      const countDownItem = this.countDownArr[this.countDownIndex];
      const { isEnd } = countDownItem;
      this.currentItemInfo = { ...countDownItem };
      // 是这道题的最后一个，需要触发保存接口，然后才能继续下一题
      hideLoading();
      if (isEnd) {
        this.startRecord();
        return;
      }
      this.startNextCountDown();
      //  倒计时结束，判断当前是不是最后一个，
      // 如果是就请求保存结果，
      // 成功后在继续播放下一个
    }
  }
};
</script>

<style lang="less" scoped>
.listen_content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  .content {
    flex: 2;
    padding: 10px;
    box-sizing: border-box;
    background: white;
    overflow-y: scroll;
    border-radius: 8px;
    .title {
      font-size: 40rpx;
      background: #e9e9e9;
      border-radius: 15px;
      padding: 0 15px;
      color: gray;
      display: inline-block;
      margin-bottom: 20px;
    }
    .text_content {
      text-align: left;
      text-indent: 2em;
      color: #666;
    }
    .outer_question {
      text-align: left;
    }
  }
}
</style>
