<template>
  <div class="listen_content">
    <div class="content">
      <div class="title" v-if="startAnswerQues && questionList.length > 1">
        {{ curtQIndex + 1 + usedListLength }}/{{ questionList.length }}
      </div>
      <div class="text_content" v-if="!startAnswerQues">
        {{ content }}
      </div>
      <div v-else>
        <template v-for="(item, itemIndex) in questionList">
          <div v-if="curtQIndex == itemIndex" :key="item.id">
            <template v-if="showAllQuestions">
              <div
                class="question_outer"
                v-for="(itemLink, itemLinkIndex) in item.link"
                :key="itemLinkIndex"
              >
                <div>
                  <div>Question {{ itemLinkIndex + 1 }}:</div>
                  <div class="question_item">{{ itemLink.question.title }}</div>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="question_outer"
                v-for="(itemLink, itemLinkIndex) in item.link"
                :key="itemLinkIndex"
              >
                <div v-if="itemLinkIndex == questionInnerIndex">
                  <div>Question {{ itemLinkIndex + 1 }}:</div>
                  <div class="question_item">{{ itemLink.question.title }}</div>
                  <template v-if="itemLink.question.content">
                    <div>提示：</div>
                    <div class="question_item">
                      {{ itemLink.question.content }}
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <!-- notifyReset 当出现评测初始化，评测错误、录音结束后等通知组件清除定时器等操作 -->
    <ExamFooter
      @endRecord="recorderEnd"
      @goOnPlay="goOnPlay"
      @endDown="countDownEnd"
      :source="source"
      :currentItem="currentItem"
      :isNewCountDown="isNewCountDown"
      :recordStatus="recordStatus"
      :notifyReset="notifyReset"
      needRecord
    ></ExamFooter>
  </div>
</template>

<script>
import ExamFooter from '../exam-footer';
import globalConfig from '@/store/global_config';
import { PUBLIC_DATA, indexToUpper } from '@/utils/common';
import { formatUsedData } from './index';
import { hideLoading, showLoading } from '../../../../utils/common';
const { imgUrl, defaultRecordUrl } = PUBLIC_DATA;
export default {
  components: { ExamFooter },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    recordStatus() {
      return globalConfig.state.recorderStatus;
    }
  },
  props: {
    itemInfo: {
      default: () => {},
      type: Object
    },
    bookId: {
      default: '',
      type: String
    },
    bookChapterId: {
      default: '',
      type: String
    },
    typeId: {
      default: '',
      type: String
    },
    gameId: {
      default: '',
      type: String
    },
    source: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      imgUrl: imgUrl,
      // 当前问题索引
      curtQIndex: 0,
      // 开始答题
      startAnswerQues: false,
      // 作业要求
      content: '',
      // 问题列表
      questionList: [],
      // 倒计时数组(包含类型等)
      countDownArr: [],
      // 倒计时索引
      countDownIndex: 0,
      // 当前大问题的小问题索引
      questionInnerIndex: 0,
      // 当前问题
      currentQuestionInfo: {},
      // 正在请求保存数据
      isRequireLoading: false,
      // 总共的小题数量
      allListLength: 0,
      // 做过的小题数
      usedListLength: 0,
      // 当前倒计时右边的类型
      btnType: '',
      showAllQuestions: false,
      currentItem: {},
      isNewCountDown: false,
      notifyReset: false
    };
  },

  mounted() {
    const { introduce, questions: list } = this.itemInfo;
    this.allListLength = list.length;
    this.introduce = introduce || {};
    const {
      content,
      file_url: firstClaimUrl,
      // 录音前提示音频
      audio_url: audioUrl,
      answer_time: answerTime,
      tips_time: tipsTime,
      frequency
    } = introduce || {};
    this.content = content;
    // 如果是考试模式, 不保存进度
    const { onlyFilterNull, newArr: filterUsedData } = formatUsedData(list);
    let questions = filterUsedData;
    if (questions.length <= 0) {
      this.$emit('end');
      return;
    }
    this.usedListLength = this.allListLength - questions.length;
    globalConfig.commit(
      'changeFirstQuestion',
      !onlyFilterNull[0].link[0].question.used
    );
    const newArr = [];
    this.questionList = questions;
    // 第一个是要求
    newArr.push({
      type: 'audio',
      src: firstClaimUrl,
      text: '答题要求播放中',
      needPlusTime: true,
      btnType: 'jump',
      notAnswerQues: true
    });
    questions.forEach((item, itemIdnex) => {
      const { audio_url: questionAudio, link = [] } = item;
      // 前面加一个总的阅题时间
      newArr.push({
        type: 'tips',
        time: tipsTime * link.length,
        text: '请阅读相关题目',
        showAllQuestions: true,
        btnType: 'jump'
      });
      for (let i = 0; i < frequency; i++) {
        newArr.push({
          type: 'audio',
          src: questionAudio,
          text: `第${indexToUpper[i]}遍`,
          btnType: 'jump',
          showAllQuestions: true
        });
      }
      link.forEach((innerItem, innerItemIndex) => {
        const { title, answer, content: qTips, score } = innerItem.question;
        newArr.push({
          type: 'tips',
          time: tipsTime,
          text: '请阅读相关题目',
          innerIndex: innerItemIndex,
          outerQueIndex: itemIdnex,
          btnType: 'jump'
        });
        // 开始录音中
        newArr.push({
          type: 'audio',
          src: audioUrl || defaultRecordUrl,
          text: audioUrl ? '答题要求播放中' : '下面开始录音',
          btnType: 'jump',
          isFromLocal: !audioUrl
        });
        newArr.push({
          hisScore: score,
          // 问题
          question: title,
          // 答案
          answerObj: JSON.parse(answer),
          qestionTips: qTips,
          id: innerItem.question_id,
          type: 'tips',
          time: answerTime,
          isEnd: true,
          // 他是第几个大问题的子项
          outerQueIndex: itemIdnex,
          btnType: 'record',
          text: '录音中',
          // 他是问题中的第几个子项目
          innerIndex: innerItemIndex
        });
      });
    });
    this.countDownArr = newArr;
    this.startNextCountDown();
  },

  methods: {
    goOnPlay() {
      this.startNextCountDown();
    },

    // 失败回调函数(播放错误，等失败回调)
    errorCallback() {
      this.countDownIndex = this.countDownIndex - 1;
      this.countDownEnd();
    },

    startNextCountDown() {
      const countDownItem = this.countDownArr[this.countDownIndex];
      const {
        type,
        btnType,
        showAllQuestions,
        notAnswerQues,
        innerIndex,
        outerQueIndex
      } = countDownItem;
      this.notifyReset = false;
      this.btnType = btnType;
      this.startAnswerQues = !notAnswerQues;
      this.showAllQuestions = !!showAllQuestions;
      if (type === 'tips') {
        this.questionInnerIndex = innerIndex;
        this.curtQIndex = outerQueIndex || this.curtQIndex;
      }
      this.currentItem = { ...countDownItem };
      setTimeout(() => {
        this.isNewCountDown = !this.isNewCountDown;
      }, 0);
    },

    countDownEnd() {
      // 如果正在录音还没保存就停止，保存完毕后回调用这个方法
      if (this.isRequireLoading) {
        return;
      }
      if (this.countDownIndex + 1 >= this.countDownArr.length) {
        console.log('已经是本类型最后一个了，需要触发父组件事件');
        this.$emit('end');
        return;
      }
      this.countDownIndex += 1;
      // 验证当前播放结束的那个是不是这道题目的最后一个
      const countDownItem = this.countDownArr[this.countDownIndex];
      const { isEnd } = countDownItem;
      this.currentQuestionInfo = { ...countDownItem };
      //  倒计时结束，判断当前是不是录音选项，
      if (isEnd) {
        this.startRecord();
        return;
      }
      // 如果不是就开始下一个倒计时
      this.startNextCountDown();
    },

    startRecord() {
      const {
        id: questionId,
        hisScore,
        qestionTips,
        answerObj,
        time
      } = this.currentQuestionInfo;
      globalConfig.commit('startRecorder', {
        elId: 'iRecorder',
        recordParams: {
          ...this.recordObj({ answerObj, time, qestionTips }),
          rank: hisScore
        },
        getEvalMessage: data => this.handleSaveRecordData(data, questionId),
        startCallback: () => {
          hideLoading();
          this.isRequireLoading = true;
          this.startNextCountDown();
        },
        stopCallback: () => {
          showLoading('评测中');
        }
      });
    },

    // 停止录音
    recorderEnd() {
      showLoading('评测中');
      globalConfig.commit('stopRecorder');
    },

    formatAnswerText(answerArr, qestionTips) {
      const lowerAnswerArr = [];
      // 答案中可能包含.等标点符号需要排除，否则后面的比对时候会出问题；例如: big.与big(评测会收到一对一错)
      const newAnswerArr = [];
      // 正确答案
      answerArr.forEach(item => {
        // 变为小写，然后替换点 结尾
        const lowerItem = String(item)
          .replace(/\.$/, '')
          .toLowerCase();
        newAnswerArr.push(lowerItem);
        lowerAnswerArr.push({
          text: lowerItem,
          answer: 2
        });
      });
      // 正确答案
      const newArr = [...lowerAnswerArr];
      // 错误答案
      const qestionTipArr = qestionTips ? qestionTips.split('/') : [];
      qestionTipArr.forEach(item => {
        const itemReplace = String(item)
          .replace(/\.$/, '')
          .toLowerCase();
        if (newAnswerArr.indexOf(itemReplace) === -1) {
          newArr.push({
            text: itemReplace,
            answer: 0
          });
        }
      });
      return newArr;
    },

    recordObj({ answerObj, time, qestionTips }) {
      const newObj = {
        // 表示英文选择题题型
        coreType: 'en.choc.score',
        // 设置可能的正确回答;lm 中的每个 text 表示一种正确的回答
        lm: this.formatAnswerText(answerObj.text, qestionTips),
        // 提问问题的文本
        evalTime: time * 1000,
        precision: 0.1,
        attachAudioUrl: 1
      };
      return newObj;
    },

    handleSaveRecordData(data, questionId) {
      showLoading('请稍候');
      const payload = {
        book_chapter_id: this.bookChapterId,
        gameId: this.gameId,
        typeId: this.typeId,
        question_id: questionId,
        book_id: this.bookId,
        source: this.source,
        read_result: data
      };
      globalConfig.dispatch('submitUnitUserAnswer', payload).then(() => {
        setTimeout(() => {
          hideLoading();
          globalConfig.commit('changeFirstQuestion', false);
          this.isRequireLoading = false;
          this.countDownEnd();
        }, 1000);
      });
    }
  }
};
</script>

<style lang="less" scoped>
.listen_content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  .content {
    flex: 2;
    box-sizing: border-box;
    background: white;
    overflow-y: scroll;
    border-radius: 8px;
    .title {
      background: #e9e9e9;
      border-radius: 30px;
      padding: 0 30px;
      color: gray;
      display: inline-block;
      margin-bottom: 20px;
    }
    .text_content {
      text-align: left;
      text-indent: 2em;
      color: #666;
    }
    .question_outer {
      text-align: left;
      margin-top: 20px;
      .question_item {
        text-indent: 2em;
      }
    }
  }
}
</style>
