<template>
  <div class="footer">
    <span
      @click="goOnPlay"
      :style="{ display: playStatus ? 'none' : 'inline', color: themeColor }"
      class="anticon-yyashow anticon-bofang play_icon"
    ></span>
    <div
      class="outer_container"
      :style="{ display: playStatus ? 'flex' : 'none' }"
    >
      <div class="outer_submit">
        <div class="outer_box">
          <div class="box" :style="{ backgroundColor: themeColor }">
            <div class="left_box">
              <div
                class="left_item"
                :style="{ transform: 'rotate(' + letRotate + 'deg)' }"
              ></div>
            </div>
            <div class="right_box">
              <div
                class="right_item"
                :style="{ transform: 'rotate(' + rightRotate + 'deg)' }"
              ></div>
            </div>
            <div class="mask">
              <span
                :style="{ color: themeColor }"
                v-if="recordStatus"
                @click="stopRecord"
                class="anticon-yyashow anticon-luyin2"
              ></span>
              <template v-else>
                <span
                  v-if="pauseStatus"
                  @click="playCountDown"
                  :style="{ color: themeColor }"
                  class="anticon-yyashow anticon-bofang"
                ></span>
                <span
                  v-else
                  @click="pauseCountDown"
                  :style="{ color: themeColor }"
                  class="anticon-yyashow anticon-zanting"
                ></span>
              </template>
            </div>
          </div>
          <div class="right_text">
            <span>{{ countDownText }}</span>
            <span>{{ minutes }}:{{ seconds }}</span>
          </div>
        </div>
        <div
          v-if="source == 1 && !recordStatus"
          @click="toSubmit"
          class="submit_btn"
          :style="{ borderColor: themeColor, color: themeColor }"
        >
          {{ btnTypeText }}
        </div>
      </div>
      <span class="record_text" v-if="recordStatus">点击结束</span>
    </div>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue';
import globalConfig from '../../../store/global_config';
import {
  handleWithUrl,
  PUBLIC_DATA,
  showLoading,
  hideLoading
} from '../../../utils/common';
const { defaultRecordTime } = PUBLIC_DATA;
export default {
  beforeDestroy() {
    clearInterval(this.timer);
    this.destoryAudioObj();
  },

  props: {
    currentItem: {
      default: () => {},
      type: Object
    },
    recordStatus: {
      default: false,
      type: Boolean
    },
    source: {
      default: '',
      type: String
    },
    isNewCountDown: {
      default: false,
      type: Boolean
    },
    needRecord: {
      default: false,
      type: Boolean
    },
    notifyReset: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    }
  },
  data() {
    return {
      timer: '',
      letRotate: 0,
      rightRotate: 0,
      currentRotate: 0,
      minutes: '00',
      seconds: '00',
      // 按钮显示文本
      btnTypeText: '',
      pauseStatus: false,
      remainingTime: 0,
      countDownText: '',
      // 播放状态
      playStatus: true
    };
  },
  watch: {
    isNewCountDown(newV, oldV) {
      if (newV !== oldV) {
        this.pauseStatus = false;
        this.handleWithCurrenItem(this.currentItem);
      }
    },

    // 父组件通知子组件需要重置一些状态时候
    notifyReset(newValue) {
      if (newValue) {
        console.log('outer notify examFooter');
        this.pauseStatus = false;
        this.clearTimerAndRotate();
      }
    }
  },

  methods: {
    /** 继续播放 */
    goOnPlay() {
      this.$emit('goOnPlay');
    },

    // 处理当期项目
    handleWithCurrenItem(currentItem) {
      const {
        type,
        src,
        text,
        time,
        needPlusTime,
        btnType,
        isFromLocal
      } = currentItem;
      const btnTypeObj = {
        submit: '提交',
        jump: '跳过'
      };
      this.btnTypeText = btnTypeObj[btnType];
      switch (type) {
        case 'audio':
          // 音频
          this.playAuioBySrc(src, text, needPlusTime, isFromLocal);
          break;
        case 'tips':
          // 倒计时（审题和答题）
          // 点击跳过-过快时候会出现正在加载音频，所以要hideloading
          hideLoading();
          this.readOrAnwerQUestion(time, text);
          break;
        default:
          break;
      }
    },

    // 播放音频
    playAuioBySrc(audioSrc, text, needPlusTime, isFromLocal) {
      showLoading('正在加载音频');
      this.destoryAudioObj();
      this.currentAudio = new Audio();
      this.currentAudio.src = handleWithUrl(audioSrc);
      this.currentAudio.play();
      this.countDownText = text;
      this.handleWithAudioEvent(needPlusTime, isFromLocal);
    },

    handleWithAudioEvent(needPlusTime, isFromLocal) {
      this.currentAudio.onerror = () => {
        this.destoryAudioObj();
        Modal.confirm({
          centered: true,
          okText: '确定',
          content: '音频加载错误，请重试',
          cancelText: '',
          onOk() {
            console.log('点击了错误');
          },
          onCancel() {}
        });
      };
      this.currentAudio.onended = () => {
        this.destoryAudioObj();
      };
      this.currentAudio.onplay = () => {
        this.playStatus = true;
        hideLoading();
        if (isFromLocal) {
          this.startCountDown(+defaultRecordTime);
          this.playStatus = true;
        }
      };
      this.currentAudio.oncanplay = () => {
        hideLoading();
        this.playStatus = true;
        if (isFromLocal) {
          return;
        }
        const duration = Math.ceil(this.currentAudio.duration);
        if (needPlusTime) {
          this.startCountDown(+duration + 4);
        } else {
          this.startCountDown(duration);
        }
      };
    },

    destoryAudioObj() {
      if (this.currentAudio) {
        this.currentAudio.pause();
        this.currentAudio.onplay = '';
        this.currentAudio.oncanplay = '';
        this.currentAudio = null;
        this.handleWitnEndItem();
      }
    },

    readOrAnwerQUestion(tipsTime, tipsText) {
      this.playStatus = true;
      this.countDownText = tipsText;
      this.startCountDown(tipsTime);
    },

    // 暂停倒计时
    pauseCountDown() {
      clearInterval(this.timer);
      this.pauseStatus = true;
      if (this.currentAudio) {
        this.currentAudio.pause();
      }
    },
    // 暂停后播放
    playCountDown() {
      this.pauseStatus = false;
      if (this.currentAudio) {
        this.currentAudio.play();
      }
      this.startCountDown(this.remainingTime);
    },

    // 停止录音
    stopRecord() {
      this.playStatus = false;
      this.pauseStatus = false;
      clearInterval(this.timer);
      this.clearTimerAndRotate();
      this.$emit('endRecord');
    },

    startCountDown(countDownTime) {
      let calcCountDownNum = countDownTime;
      this.remainingTime = countDownTime;
      let minutes = Math.floor(calcCountDownNum / 60);
      let seconds = Math.floor(calcCountDownNum % 60);
      minutes = minutes < 0 ? 0 : minutes;
      seconds = seconds < 0 ? 0 : seconds;
      let rotate = this.currentRotate;
      let allRotateDeg = 360 - rotate;
      const everyDeg = allRotateDeg / calcCountDownNum;
      this.minutes = minutes < 10 ? '0' + minutes : minutes;
      this.seconds = seconds < 10 ? '0' + seconds : seconds;
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        if (rotate >= 360) {
          this.clearTimerAndRotate();
          rotate = 0;
          // 如果当前项需要录音
          // 并且isEnd是true,倒计时结束不需要触发endDown事件
          // 因为有录音回调
          if (this.needRecord && this.currentItem.isEnd) {
            return;
          }
          this.$emit('endDown');
          return;
        }
        rotate += everyDeg;
        this.currentRotate += everyDeg;
        this.remainingTime--;
        let minutes = Math.floor(this.remainingTime / 60);
        let seconds = Math.floor(this.remainingTime % 60);
        minutes = minutes < 0 ? 0 : minutes;
        seconds = seconds < 0 ? 0 : seconds;
        this.minutes = minutes < 10 ? '0' + minutes : minutes;
        this.seconds = seconds < 10 ? '0' + seconds : seconds;
        // 右边圆现旋转180
        if (rotate <= 180) {
          // 0-180是右边的旋转
          this.rightRotate = rotate;
        } else {
          this.rightRotate = 180;
          // 大于180以后左边圆旋转180
          this.letRotate = rotate - 180 > 180 ? 180 : rotate - 180;
        }
      }, 1000);
    },

    clearTimerAndRotate() {
      clearInterval(this.timer);
      this.rightRotate = 0;
      this.letRotate = 0;
      this.currentRotate = 0;
      this.remainingTime = 0;
      this.destoryAudioObj();
    },

    toSubmit() {
      this.clearTimerAndRotate();
      showLoading();
      // 点击提交或者跳过播放要求
      this.$emit('endDown');
    },
    // 如果当前项isEnd是true时候，播放状态为false
    handleWitnEndItem() {
      if (this.currentItem.isEnd) {
        this.playStatus = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.footer {
  display: flex;
  align-items: center;
  height: 100px;
  .play_icon {
    font-size: 60px;
  }
  .outer_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .outer_submit {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      .outer_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        .box {
          /* 最外层的盒子 */
          width: 58px;
          height: 58px;
          margin: 0 auto;
          position: relative;
          border-radius: 50%;
          float: left;
          .left_box,
          .right_box {
            /* 
        左右两边用于 隐藏 旋转的div的盒子 
        通过overflow来隐藏内部div旋转出去的部分
      */
            position: absolute;
            top: -1px;
            width: 30px;
            height: 60px;
            overflow: hidden;
            z-index: 1;
            font-size: 0;
          }

          .left_box {
            left: 0px;
          }

          .right_box {
            right: 0px;
          }

          /* 
       这是需要旋转的div(没有被mask遮盖展示出来的部分作为倒计时的线条)
       为了方便理解，下面用deeppink和cyan分别设置了左右两边div的颜色
      */
          .left_item,
          .right_item {
            width: 30px;
            height: 60px;
          }

          /*
        1.设置圆角，圆角大小为高度的一半
        2.这只旋转的中心店，这是左边圆，中心点设置到右边中心点，右边圆则设置到左边中心点
        */
          .left_item {
            border-top-left-radius: 60px;
            border-bottom-left-radius: 60px;
            -webkit-transform-origin: right center;
            transform-origin: right center;
            background-color: #fff;
          }

          .right_item {
            border-top-right-radius: 60px;
            border-bottom-right-radius: 60px;
            -webkit-transform-origin: left center;
            transform-origin: left center;
            background-color: #fff;
          }

          .mask {
            /* 遮住div多余的部分，呈现出线条的效果 */
            position: absolute;
            top: 2px;
            left: 2px;
            right: 2px;
            bottom: 2px;
            z-index: 2;
            border-radius: 50%;
            background-color: #f5f5f5;
            .anticon-yyashow {
              font-size: 54px;
              position: absolute;
              top: -1px;
              bottom: 0;
              right: 0;
              margin: 0 auto;
              line-height: 105%;
              left: 0px;
              &.anticon-luyin2 {
                top: -1px;
              }
            }
          }
        }
        .right_text {
          font-size: 1rem;
          margin-left: 10px;
        }
      }
      .submit_btn {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        line-height: 40px;
        text-align: center;
        border: solid 1px white;
        font-size: 1rem;
      }
    }
    .record_text {
      font-size: 0.8rem;
      color: #999;
      text-align: center;
      width: 58px;
    }
  }
}
</style>
