<template>
  <div class="listen_content">
    <div class="content">
      <div class="title" v-if="startAnswerQues && allListLength > 1">
        {{ curtQIndex + 1 + usedListLength }}/{{ allListLength }}
      </div>
      <div class="text_content" v-if="!startAnswerQues">
        {{ content }}
      </div>
      <div v-else>
        <template v-for="(item, itemIndex) in questionList">
          <div
            v-if="curtQIndex == itemIndex"
            class="outer_question"
            :key="item.id"
          >
            <div class="question_content">
              {{ item.content }}
            </div>
            <template v-for="(linkItem, linkIndex) in item.link">
              <div class="question_outer" :key="linkItem.id">
                <div class="question_title">第{{ linkIndex + 1 }}题:</div>
                <a-radio-group
                  class="radio_group"
                  @change="radioChange($event, linkItem.question_id, linkIndex)"
                >
                  <label
                    class="label_item"
                    v-for="(itemImg, itemImgIndex) in linkItem.question.items"
                    :key="itemImgIndex"
                  >
                    <a-radio
                      :color="themeColor"
                      class="lable_radio"
                      :value="itemImg.id"
                    ></a-radio>
                    <div>{{ indexPrefix[itemImgIndex] }}:</div>
                    <span class="option_text">{{ itemImg.option_text }}</span>
                  </label>
                </a-radio-group>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <ExamFooter
      @goOnPlay="goOnPlay"
      :source="source"
      @endDown="countDownEnd"
      :currentItem="currentItem"
      :isNewCountDown="isNewCountDown"
    ></ExamFooter>
  </div>
</template>

<script>
import ExamFooter from '../exam-footer';
import globalConfig from '@/store/global_config';
import {
  PUBLIC_DATA,
  indexPrefix,
  indexToUpper,
  showLoading,
  hideLoading
} from '@/utils/common';
import { formatUsedData } from './index';
const { imgUrl } = PUBLIC_DATA;
export default {
  components: { ExamFooter },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    }
  },
  props: {
    itemInfo: {
      default: () => {},
      type: Object
    },
    bookId: {
      default: '',
      type: String
    },
    bookChapterId: {
      default: '',
      type: String
    },
    typeId: {
      default: '',
      type: String
    },
    gameId: {
      default: '',
      type: String
    },
    source: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      indexPrefix: indexPrefix,
      imgUrl: imgUrl,
      // 当前问题索引
      curtQIndex: 0,
      // 开始答题
      startAnswerQues: false,
      // 作业要求
      content: '',
      // 问题列表
      questionList: [],
      // 倒计时数组(包含类型等)
      countDownArr: [],
      // 倒计时索引
      countDownIndex: 0,
      // 当前题目选择的答案
      userRaidoChoose: [],
      // 总共的小题数量
      allListLength: 0,
      // 做过的小题数
      usedListLength: 0,
      currentItem: {},
      isNewCountDown: false
    };
  },

  onUnload() {
    this.destoryAudioObj();
  },

  onHide() {
    this.pausePlay();
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      const { introduce, questions: list } = this.itemInfo;
      this.introduce = introduce || {};
      this.allListLength = list.length;
      const {
        content,
        file_url: firstClaimUrl,
        answer_time: answerTime,
        tips_time: tipsTime,
        frequency
      } = this.introduce;
      this.content = content;
      const { newArr: filterUsed, onlyFilterNull } = formatUsedData(list);
      // 如果是考试模式, 不保存进度
      let questions = filterUsed;
      this.usedListLength = list.length - questions.length;
      if (questions.length === 0) {
        this.$emit('end');
        return;
      }
      this.questionList = questions;
      globalConfig.commit(
        'changeFirstQuestion',
        !onlyFilterNull[0].link[0].question.used
      );
      const newArr = [];
      // 第一个是要求;
      newArr.push({
        type: 'audio',
        src: firstClaimUrl,
        text: '答题要求播放中',
        needPlusTime: true,
        btnType: 'jump'
      });
      questions.forEach(item => {
        const {
          file_url: questionClaimUrl,
          audio_url: itemAudioUrl,
          id,
          tips_time: itemTipsTime,
          answer_time: itemAnswerTime,
          frequency: itemFrequency
        } = item;
        const hisTipsTime = itemTipsTime || tipsTime;
        const hisAnswerTime = itemAnswerTime || answerTime;
        const hisFrequency = itemFrequency || frequency;
        newArr.push({
          type: 'audio',
          src: questionClaimUrl,
          text: '答题要求播放中',
          startShowQuestion: true,
          needPlusTime: true,
          btnType: 'jump'
        });
        newArr.push({
          type: 'tips',
          time: hisTipsTime,
          text: '请阅读相关题目',
          btnType: 'jump',
          startShowQuestion: true
        });
        for (let i = 0; i < hisFrequency; i++) {
          newArr.push({
            type: 'audio',
            src: itemAudioUrl,
            text: `第${indexToUpper[i]}遍`,
            btnType: 'jump',
            startShowQuestion: true
          });
        }
        newArr.push({
          id,
          type: 'tips',
          time: hisAnswerTime,
          isEnd: true,
          text: '请选择',
          btnType: 'submit',
          startShowQuestion: true
        });
      });
      this.countDownArr = newArr;
      this.startNextCountDown();
    },

    goOnPlay() {
      this.startNextCountDown();
    },
    // 失败回调函数(播放错误，等失败回调)
    errorCallback() {
      this.countDownIndex = this.countDownIndex - 1;
      this.countDownEnd();
    },

    // 单选更改
    radioChange(event, parentId, linkIndex) {
      const targetValue = event.target.value;
      const newArr = [...this.userRaidoChoose];
      newArr[linkIndex] = {
        id: String(parentId),
        user_answer: String(targetValue)
      };
      this.userRaidoChoose = [...newArr];
    },

    startNextCountDown() {
      if (this.countDownIndex >= this.countDownArr.length) {
        if (this.isEmitEnd) {
          return;
        }
        this.isRequesting = false;
        this.isEmitEnd = true;
        console.log('已经是本类型最后一个了，需要触发父组件事件');
        this.$emit('end');
        return;
      }
      const countDownItem = this.countDownArr[this.countDownIndex];
      const { startShowQuestion } = countDownItem;
      this.startAnswerQues = this.startAnswerQuesn || startShowQuestion;
      this.currentItem = { ...countDownItem };
      setTimeout(() => {
        this.isNewCountDown = !this.isNewCountDown;
      }, 0);
    },

    countDownEnd() {
      // 验证当前播放结束的那个是不是这道题目的最后一个
      const countDownItem = this.countDownArr[this.countDownIndex];
      const { isEnd, id: questionId } = countDownItem || {};
      this.countDownIndex += 1;
      //  倒计时结束，判断当前是不是最后一个，
      // 如果是就请求保存结果，
      // 成功后在继续播放下一个
      if (isEnd) {
        // 是这道题的最后一个，需要触发保存接口，然后才能继续下一题
        this.handleSaveUserData(questionId);
        return;
      }
      this.startNextCountDown();
    },

    // 保存用户数据
    handleSaveUserData(questionId) {
      const currentLinkArr = this.questionList[this.curtQIndex];
      const questionArr = [];
      currentLinkArr.link.forEach((itemLink, itemLinkIndex) => {
        const { question } = itemLink;
        const { id } = question;
        const { user_answer: userAnswer } =
          this.userRaidoChoose[itemLinkIndex] || {};
        questionArr.push({
          id: String(id),
          user_answer: userAnswer || ''
        });
      });
      const payload = {
        book_chapter_id: this.bookChapterId,
        gameId: this.gameId,
        typeId: this.typeId,
        question: questionArr,
        question_id: questionId,
        book_id: this.bookId,
        source: this.source
      };
      if (this.isRequesting) {
        return;
      }
      this.isRequesting = true;
      showLoading();
      globalConfig
        .dispatch('submitUnitUserAnswer', payload)
        .then(() => {
          hideLoading();
          globalConfig.commit('changeFirstQuestion', false);
          hideLoading();
          this.curtQIndex++;
          this.userRaidoChoose = [];
          this.startNextCountDown();
        })
        .catch(() => {
          hideLoading();
          this.isRequesting = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.listen_content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  .content {
    flex: 2;
    padding: 30px;
    box-sizing: border-box;
    background: white;
    overflow-y: scroll;
    border-radius: 8px;
    .title {
      font-size: 40rpx;
      background: #e9e9e9;
      border-radius: 30rpx;
      padding: 0 30px;
      color: gray;
      display: inline-block;
      margin-bottom: 20px;
    }
    .text_content {
      text-align: left;
      text-indent: 2em;
      color: #666;
    }
    .outer_question {
      text-align: left;
      box-sizing: border-box;
      .question_content {
        text-indent: 2em;
        color: #666;
      }
      .question_outer {
        padding: 20rpx 0;
        box-sizing: border-box;
        .radio_group {
          margin-top: 20px;
          width: 100%;
          font-size: 1rem;
          .label_item {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            .lable_radio {
              margin-right: 10px;
              position: relative;
            }
            .option_text {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
