<template>
  <div class="listen_content">
    <div class="inner_content">
      <div class="text_center" v-if="startAnswerQues && allLength > 1">
        <span class="game_number">
          {{ curtQIndex + 1 + usedLength }}/{{ allLength }}
        </span>
      </div>
      <div class="text_content" v-if="!startAnswerQues">
        {{ content }}
      </div>
      <template v-else>
        <template v-for="(item, itemIndex) in questionList">
          <div
            v-if="curtQIndex == itemIndex"
            class="outer_question"
            :key="item.id"
          >
            {{ item.title }}
          </div>
        </template>
        <div style="height:10px;"></div>
        <template v-for="(item, itemIndex) in questionContentList">
          <div v-if="isShowQuestion && item.content" class="question_content">
            <v-html v-html="item.content"></v-html>
          </div>
        </template>
      </template>
    </div>
    <ExamFooter
      @endRecord="recorderEnd"
      @goOnPlay="goOnPlay"
      @endDown="countDownEnd"
      :source="source"
      :currentItem="currentItem"
      :isNewCountDown="isNewCountDown"
      :recordStatus="recorderStatus"
      :notifyReset="notifyReset"
      needRecord
    ></ExamFooter>
  </div>
</template>

<script>
import globalConfig from '@/store/global_config';
import { PUBLIC_DATA, getStartRecordObj, indexToUpper } from '@/utils/common';
import ExamFooter from '../exam-footer';
import { onlyFormatUsedData } from './index';
import { showLoading, hideLoading } from '../../../../utils/common';
const { imgUrl, defaultRecordUrl } = PUBLIC_DATA;

export default {
  components: { ExamFooter },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    evalAppId() {
      return globalConfig.state.evalAppId;
    },
    // 是否在录音中
    recorderStatus() {
      return globalConfig.state.recorderStatus;
    }
  },
  props: {
    itemInfo: {
      default: () => {},
      type: Object
    },
    bookId: {
      default: '',
      type: String
    },
    bookChapterId: {
      default: '',
      type: String
    },
    typeId: {
      default: '',
      type: String
    },
    gameId: {
      default: '',
      type: String
    },
    source: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      imgUrl: imgUrl,
      // 当前问题索引
      curtQIndex: 0,
      // 倒计时文本
      countDownText: '',
      // 倒计时
      countDownNum: 0,
      // 开始答题
      startAnswerQues: false,
      // 作业要求
      content: '',
      // 问题列表
      questionList: [],
      // 倒计时数组(包含类型等)
      countDownArr: [],
      // 倒计时索引
      countDownIndex: 0,
      // 当前录音对象包含的信息
      currentItemInfo: {},
      requestLoading: false,
      // 总共的小题数量
      allLength: 0,
      // 做过的小题数
      usedLength: 0,
      // 当前倒计时右边的类型
      btnType: '',
      isNewCountDown: false,
      currentItem: {},
      notifyReset: false,
      isShowQuestion: true,
      questionContentList: ''
    };
  },

  mounted() {
    const { introduce, questions: list } = this.itemInfo;
    this.allLength = list.length;
    this.introduce = introduce || {};
    const {
      content,
      file_url: firstClaimUrl,
      answer_time: answerTime,
      tips_time: tipsTime,
      audio_url: audioUrl,
      frequency
    } = this.introduce;
    this.content = content;
    // 如果是考试模式, 不保存进度
    let questions = onlyFormatUsedData(list);
    if (questions.length === 0) {
      this.$emit('end');
      return;
    }
    this.usedLength = list.length - questions.length;

    this.questionList = questions;
    globalConfig.commit('changeFirstQuestion', !list[0].used);
    // 第一个是要求
    const newArr = [];
    const newArrContent = [];
    newArr.push({
      type: 'audio',
      src: firstClaimUrl,
      text: '答题要求播放中',
      btnType: 'jump'
    });
    questions.forEach(item => {
      const { file_url: itemAudioUrl, id } = item;
      newArr.push({
        type: 'tips',
        time: tipsTime,
        text: '请阅读相关题目',
        btnType: 'jump'
      });

      if (itemAudioUrl && this.source == 1) {
        for (let i = 0; i < frequency; i++) {
          newArr.push({
            type: 'audio',
            src: itemAudioUrl,
            text: `第${indexToUpper[i]}遍`,
            btnType: 'jump'
          });
        }
      }

      newArr.push({
        type: 'audio',
        src: audioUrl || defaultRecordUrl,
        text: audioUrl ? '答题要求播放中' : '下面开始录音',
        btnType: 'jump',
        isFromLocal: !audioUrl
      });
      newArr.push({
        id,
        type: 'tips',
        time: answerTime,
        isEnd: true,
        text: '录音中',
        title: item.title,
        btnType: 'record'
      });
      newArrContent.push({
        content: item.content.replace(/\n/g, '<br/>')
      });
    });
    this.questionContentList = newArrContent;
    this.countDownArr = newArr;
    this.startNextCountDown();
  },

  methods: {
    goOnPlay() {
      this.startNextCountDown();
    },

    // 失败回调函数(播放错误，等失败回调)
    errorCallback() {
      this.countDownIndex = this.countDownIndex - 1;
      this.countDownEnd();
    },

    startNextCountDown() {
      const countDownItem = this.countDownArr[this.countDownIndex];
      const { type, btnType } = countDownItem;
      this.btnType = btnType;
      if (type === 'tips') {
        this.startAnswerQues = true;
      }
      this.notifyReset = false;
      this.currentItem = { ...countDownItem };
      setTimeout(() => {
        this.isNewCountDown = !this.isNewCountDown;
      }, 0);
    },

    // 停止录音
    recorderEnd() {
      showLoading('评测中');
      globalConfig.commit('stopRecorder');
    },

    handleSaveRecordData(data, questionId) {
      const payload = {
        question_id: questionId,
        book_chapter_id: this.bookChapterId,
        gameId: this.gameId,
        typeId: this.typeId,
        book_id: this.bookId,
        source: this.source,
        read_result: data
      };
      globalConfig.dispatch('submitUnitUserAnswer', payload).then(() => {
        hideLoading();
        setTimeout(() => {
          globalConfig.commit('changeFirstQuestion', false);
          this.requestLoading = false;
          this.curtQIndex++;
          this.countDownEnd();
        }, 1000);
      });
    },

    countDownEnd() {
      if (this.requestLoading) {
        return;
      }
      if (this.countDownIndex + 1 >= this.countDownArr.length) {
        console.log('已经是本类型最后一个了，需要触发父组件事件');
        this.$emit('end');
        return;
      }
      this.countDownNum = 0;
      // 验证当前播放结束的那个是不是这道题目的最后一个
      this.countDownIndex += 1;
      const countDownItem = this.countDownArr[this.countDownIndex];
      const { isEnd, title, time, id } = countDownItem;
      this.currentItemInfo = {
        id,
        title,
        time
      };
      if (isEnd) {
        this.isShowQuestion = false;
        this.startStoreRecord();
        return;
        // 是这道题的最后一个，需要触发保存接口，然后才能继续下一题
      }
      this.startNextCountDown();
      //  倒计时结束，判断当前是不是最后一个，
      // 如果是就请求保存结果，
      // 成功后在继续播放下一个
    },

    getEvalMessage(data, questionId) {
      showLoading('请稍候');
      this.handleSaveRecordData(data, questionId);
    },

    startStoreRecord() {
      const { score, num } = this.introduce;
      const { id: questionId, time, title } = this.currentItemInfo;
      globalConfig.commit('startRecorder', {
        elId: 'iRecorder',
        recordParams: {
          ...getStartRecordObj('pred', (+time + 1) * 1000, title),
          rank: score / num
        },
        getEvalMessage: data => this.getEvalMessage(data, questionId),
        startCallback: () => {
          this.requestLoading = true;
          hideLoading();
          this.startNextCountDown();
        },
        stopCallback: () => {
          showLoading('评测中');
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../exam.less';
.listen_content1 {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    flex: 2;
    padding: 30rpx;
    box-sizing: border-box;
    background: white;
    overflow-y: scroll;
    border-radius: 8px;
    .title {
      font-size: 40rpx;
      background: #e9e9e9;
      border-radius: 30rpx;
      padding: 0 30rpx;
      color: gray;
      display: inline-block;
      margin-bottom: 20rpx;
    }
    .text_content {
      text-align: left;
      text-indent: 2em;
      font-size: 32rpx;
      color: #666;
    }
    .outer_question {
      text-align: left;
      text-indent: 2em;
      font-size: 34rpx;
    }
    .question_content {
      text-align: left;
      font-size: 32rpx;
      color: #666;
    }
  }
}
</style>
