<template>
  <div class="listen_content">
    <div v-if="showMaskImg" class="mask_outer" @click="hideMask">
      <img
        class="img_url"
        mode="widthFix"
        v-if="imgUrl"
        :src="imgUrl + picInfo[curtQIndex].img_url"
        alt
      />
    </div>
    <div class="content">
      <div class="title" v-if="allListLength > 1">
        {{ curtQIndex + 1 + usedListLength }}/{{ allListLength }}
      </div>
      <div class="text_content">
        {{ content }}
      </div>
      <template v-for="(item, itemIndex) in questionList">
        <div v-if="curtQIndex == itemIndex" :key="item.id">
          <template v-if="picInfo[curtQIndex]">
            <div class="img_box" @click="showMask">
              <img
                v-if="imgUrl"
                class="file_url"
                mode="widthFix"
                :src="imgUrl + picInfo[curtQIndex].img_url"
                srcset
                alt
              />
              <div class="right_btn">点击查看大图</div>
            </div>
            <div class="inner_word">
              <div
                v-for="(answerItme, answerItemIndex) in picInfo[curtQIndex]
                  .answer"
                :key="answerItemIndex"
                class="word_item"
              >
                <span class="inner_left">{{ answerItemIndex + 1 }}.</span>
                <span class="inner_right">{{ answerItme }}</span>
              </div>
            </div>
          </template>
          <div class="outer_question" v-if="showTips">
            {{ item.title }}
          </div>
        </div>
      </template>
    </div>
    <ExamFooter
      @endRecord="recorderEnd"
      :source="source"
      @goOnPlay="goOnPlay"
      @endDown="countDownEnd"
      :currentItem="currentItem"
      :isNewCountDown="isNewCountDown"
      :recordStatus="recordStatus"
      :notifyReset="notifyReset"
      needRecord
    ></ExamFooter>
  </div>
</template>

<script>
import globalConfig from '@/store/global_config';
import ExamFooter from '../exam-footer';
import { onlyFormatUsedData } from './index';
import { PUBLIC_DATA, getStartRecordObj, indexToUpper } from '@/utils/common';
import { fillExamBlankData } from '../../../../api/service';
import { showLoading, hideLoading } from '../../../../utils/common';
const { imgUrl, defaultRecordUrl } = PUBLIC_DATA;

export default {
  components: { ExamFooter },

  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    },
    recordStatus() {
      return globalConfig.state.recorderStatus;
    }
  },
  props: {
    itemInfo: {
      default: () => {},
      type: Object
    },
    bookId: {
      default: '',
      type: String
    },
    bookChapterId: {
      default: '',
      type: String
    },
    typeId: {
      default: '',
      type: String
    },
    gameId: {
      default: '',
      type: String
    },
    source: {
      default: '',
      type: String
    },
    fillId: {
      default: '',
      typeId: String
    }
  },
  data() {
    return {
      imgUrl: imgUrl,
      // 当前问题索引
      curtQIndex: 0,
      // 作业要求
      content: '',
      // 问题列表
      questionList: [],
      // 倒计时数组(包含类型等)
      countDownArr: [],
      // 倒计时索引
      countDownIndex: 0,
      // 当前题目选择的答案
      userRaidoChoose: '',
      // 当前录音对象包含的信息
      currentItemInfo: {},
      requestLoading: false,
      // 总共的小题数量
      allListLength: 0,
      // 做过的小题数
      usedListLength: 0,
      // 展示提示信息
      showTips: false,
      picInfo: [],
      showMaskImg: false,
      isNewCountDown: false,
      currentItem: {},
      notifyReset: false
    };
  },
  mounted() {
    if (this.fillId) {
      this.fetchFillInfo(number => {
        this.proCountDownArr(number);
      });
      return;
    }
    this.proCountDownArr(0);
  },

  methods: {
    goOnPlay() {
      this.startNextCountDown();
    },

    // 失败回调函数(播放错误，等失败回调)
    errorCallback() {
      this.countDownIndex = this.countDownIndex - 1;
      this.countDownEnd();
    },

    // 获取听力填空的信息
    fetchFillInfo(callback) {
      fillExamBlankData({
        bookId: this.bookId,
        bookChapterId: this.bookChapterId,
        typeId: this.typeId,
        gameId: this.fillId,
        source: this.source
      }).then(response => {
        const { questions, introduce } = response.data;
        const { frequency } = introduce || {};
        this.picInfo = this.getItemPicInfo(questions);
        if (callback) callback(frequency);
      });
    },

    // 产生倒计时数组
    // 听后记录和转述是同一道题目，所以听后转述这里显示是第三遍（加上之前听力记录的播放次数）
    proCountDownArr(number = 0) {
      const { introduce, questions: list } = this.itemInfo;
      this.allListLength = list.length;
      this.introduce = introduce || {};
      const {
        content,
        frequency,
        file_url: firstClaimUrl,
        answer_time: answerTime,
        tips_time: tipsTime,
        audio_url: audioUrl
      } = this.introduce;
      this.content = content;
      // 如果是考试模式, 不保存进度
      let questions = onlyFormatUsedData(list);
      if (questions.length === 0) {
        this.$emit('end');
        return;
      }
      this.questionList = questions;
      this.usedListLength = list.length - questions.length;
      globalConfig.commit('changeFirstQuestion', !list[0].used);
      // 第一个是要求
      const newArr = [];
      newArr.push({
        type: 'audio',
        src: firstClaimUrl,
        text: '答题要求播放中',
        btnType: 'jump',
        needPlusTime: true
      });
      questions.forEach(item => {
        const {
          id,
          file_url: fileUrl,
          title,
          content,
          tip_url: tipUrl,
          answer
        } = item;
        for (let i = 0; i < frequency; i++) {
          newArr.push({
            type: 'audio',
            src: fileUrl,
            text: `第${indexToUpper[+i + number]}遍`,
            btnType: 'jump',
            title: title
          });
        }
        // 下面你有2分钟的时间做转述准备,转述的开头已给出。
        newArr.push({
          type: 'audio',
          src: audioUrl || defaultRecordUrl,
          text: audioUrl ? '答题要求播放中' : '下面开始录音',
          btnType: 'jump',
          isFromLocal: !audioUrl
        });
        newArr.push({
          type: 'tips',
          time: tipsTime,
          text: '准备答题倒计时',
          btnType: 'jump',
          showTips: true
        });
        // 下面请准备录音。听到录音提示音后,在2分钟内完成转述。开始录音。
        newArr.push({
          type: 'audio',
          src: tipUrl,
          text: '答题要求播放中',
          btnType: 'jump'
        });
        newArr.push({
          id,
          type: 'tips',
          time: answerTime,
          isEnd: true,
          text: '录音中',
          btnType: 'record',
          content: content,
          answerObj: JSON.parse(answer)
        });
      });
      this.countDownArr = newArr;
      this.startNextCountDown();
    },

    getItemPicInfo(itemArr) {
      const newArr = [];
      itemArr.forEach(item => {
        const { file_url: fileUrl, link } = item;
        const answerArr = [];
        link.forEach(item => {
          if (item.question) {
            answerArr.push(item.question.used.user_answer);
          }
        });
        newArr.push({
          img_url: fileUrl,
          answer: answerArr
        });
      });
      return newArr;
    },

    startNextCountDown() {
      const countDownItem = this.countDownArr[this.countDownIndex];
      const { showTips } = countDownItem;
      if (showTips) {
        this.showTips = true;
      }
      this.notifyReset = false;
      this.currentItem = { ...countDownItem };
      setTimeout(() => {
        this.isNewCountDown = !this.isNewCountDown;
      }, 0);
    },

    // 停止录音
    recorderEnd() {
      showLoading('评测中');
      globalConfig.commit('stopRecorder');
    },

    produceArr(arrObj) {
      const newArr = [];
      arrObj.text.forEach(item => {
        newArr.push({
          text: item
        });
      });
      return newArr;
    },

    recordObj() {
      const { time, content, answerObj } = this.currentItemInfo;
      const { score, num } = this.introduce;
      return {
        ...getStartRecordObj('retell', (+time + 1) * 1000, content),
        rank: score / num,
        lm: this.produceArr(answerObj)
      };
    },

    startRecord() {
      const { id: questionId } = this.currentItemInfo;
      globalConfig.commit('startRecorder', {
        elId: 'iRecorder',
        recordParams: this.recordObj(),
        getEvalMessage: data => this.handleSaveRecordData(data, questionId),
        startCallback: () => {
          this.requestLoading = true;
          hideLoading();
          this.startNextCountDown();
        },
        stopCallback: () => {
          showLoading('评测中');
        }
      });
    },

    handleSaveRecordData(data, questionId) {
      showLoading('请稍候');
      const payload = {
        book_chapter_id: this.bookChapterId,
        gameId: this.gameId,
        typeId: this.typeId,
        question_id: questionId,
        book_id: this.bookId,
        source: this.source,
        read_result: data
      };
      globalConfig.dispatch('submitUnitUserAnswer', payload).then(() => {
        setTimeout(() => {
          globalConfig.commit('changeFirstQuestion', false);
          this.requestLoading = false;
          this.curtQIndex++;
          this.countDownEnd();
        }, 1000);
      });
    },

    countDownEnd() {
      if (this.requestLoading) {
        return;
      }
      if (this.countDownIndex + 1 >= this.countDownArr.length) {
        // console.log('已经是本类型最后一个了，需要触发父组件事件');
        this.$emit('end');
        return;
      }
      // 验证当前播放结束的那个是不是这道题目的最后一个
      this.countDownIndex += 1;
      const countDownItem = this.countDownArr[this.countDownIndex];
      const { isEnd, time, id, content, answerObj } = countDownItem;
      this.currentItemInfo = {
        content,
        id,
        time,
        answerObj
      };
      if (isEnd) {
        this.startRecord();
        return;
        // 是这道题的最后一个，需要触发保存接口，然后才能继续下一题
      }
      this.startNextCountDown();
      //  倒计时结束，判断当前是不是最后一个，
      // 如果是就请求保存结果，
      // 成功后在继续播放下一个
    },

    hideMask() {
      this.showMaskImg = false;
    },

    showMask() {
      this.showMaskImg = true;
    }
  }
};
</script>

<style lang="less" scoped>
.listen_content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  overflow: hidden;
  .content {
    flex: 2;
    box-sizing: border-box;
    background: white;
    overflow-y: scroll;
    border-radius: 8px;
    padding-bottom: 20px;
    .title {
      background: #e9e9e9;
      padding: 0 30px;
      color: gray;
      display: inline-block;
      margin-bottom: 20px;
    }
    .text_content {
      text-align: left;
      color: #666;
    }
    .img_box {
      text-align: center;
      position: relative;
      min-height: 150px;
      .file_url {
        width: 100%;
        max-width: 500px;
      }
      .right_btn {
        position: absolute;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 0px 20px;
      }
    }
    .inner_word {
      color: #666;
      text-align: left;
      .word_item {
        height: 40px;
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        .inner_left {
          margin-right: 10px;
        }
        .inner_right {
          padding-left: 10px;
          flex: 2;
          display: inline-block;
          text-align: end;
          text-align: left;
          border-bottom: solid 1px #999;
        }
      }
    }
    .outer_question {
      margin-top: 20px;
      text-align: left;
    }
  }
  .mask_outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 10000;
    .img_url {
      width: 100%;
      margin-top: 100px;
    }
  }
}
</style>
