<template>
  <div class="inner_outer_page" @scroll="outerScroll">
    <div class="title_static">
      <div class="title_box">报告解析</div>
      <div id="main" style="width: 500px;height:500px;"></div>
    </div>
    <div class="right_content">
      <div
        class="nav_scroll my_scroll"
        :class="{ fixed_title: titleFixedStatus }"
      >
        <template v-for="(navItem, navIndex) in questionList">
          <span
            :key="navItem.id"
            :style="{
              color: gameId == navItem.game_id ? themeColor : 'inherit'
            }"
            class="nav_title"
            @click="chooseTemplate(navItem, navIndex)"
          >
            {{ navItem.title }}
          </span>
        </template>
      </div>
      <div class="empty_nav" v-if="titleFixedStatus"></div>
      <div class="inner_content">
        <template v-for="(item, itemKey) in questionList">
          <div
            v-if="item.template === template && item.game_id == gameId"
            :key="itemKey"
          >
            <!-- 听力选图 -->
            <listen-choose-pic
              v-if="template === '/pages/discover/listen-choose-pic/main'"
              :item="item"
            ></listen-choose-pic>
            <!-- 听力选答 -->
            <listen-choose-answer
              v-if="template === '/pages/discover/listen-choose-answe/main'"
              :item="item"
            ></listen-choose-answer>
            <!-- 听对话选答 -->
            <listen-dialog-choose
              v-if="template === '/pages/discover/choose-dialog-answer/main'"
              :item="item"
            ></listen-dialog-choose>
            <!-- 课文朗读 -->
            <unit-text-speak
              v-if="template === '/pages/discover/unit-text-speak/main'"
              :item="item"
            ></unit-text-speak>
            <!-- 听力填空 -->
            <listen-fill-blank
              v-if="
                template === '/pages/discover/listen-fill-blank/main' ||
                  template === 'new-fill-blank'
              "
              :item="item"
            ></listen-fill-blank>
            <!-- 话题阐述 -->
            <topic-exposition
              v-if="template === '/pages/discover/topic-exposition/main'"
              :item="item"
            ></topic-exposition>
            <!-- 情景问答 -->
            <scene-answer
              v-if="template === '/pages/discover/scene-question-answer/main'"
              :item="item"
            ></scene-answer>
            <!-- 听后选择 -->
            <after-listen
              v-if="template === '/pages/discover/after-listen/main'"
              :item="item"
            ></after-listen>
            <!-- 听后回答 -->
            <listen-answer
              v-if="template === '/pages/discover/listen-answer/main'"
              :item="item"
            ></listen-answer>
            <!-- 听后转述 -->
            <listen-report
              v-if="template === '/pages/discover/listen-report/main'"
              :item="item"
            ></listen-report>
            <!-- 识读音标 -->
            <identify-symbol
              v-if="template === '/pages/discover/identify-symbol/main'"
              :item="item"
            ></identify-symbol>
            <!-- "listen-essay-answer" -->
            <listen-passage-answer
              v-if="template === 'listen-essay-answer'"
              :item="item"
            ></listen-passage-answer>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import globalConfig from '@/store/global_config';
import echarts from 'echarts';
import { fetchExamReport } from '../../api/service';
import ListenChoosePic from './components/unit-report/listen-choose-pic';
import ListenFillBlank from './components/unit-report/listen-fill-blank';
import ListenChooseAnswer from './components/unit-report/listen-choose-answer';
import ListenDialogChoose from './components/unit-report/listen-dialog-choose';
import ListenAnswer from './components/unit-report/listen-answer';
import ListenReport from './components/unit-report/listen-report';
import unitTextSpeak from './components/unit-report/unit-text-speak';
import ListenPassageAnswer from './components/unit-report/listen-passage';
import TopicExposition from './components/unit-report/topic-exposition';
import SceneAnswer from './components/unit-report/scene-answer';
import AfterListen from './components/unit-report/after-listen';
import IdentifySymbol from './components/unit-report/identify-symbol';
import { hideLoading } from '../../utils/common';
export default {
  props: {
    bookId: {
      default: '',
      type: String
    },
    chapterId: {
      default: '',
      type: String
    },
    typeId: {
      default: '',
      type: String
    },
    source: {
      default: '',
      type: String
    }
  },
  computed: {
    themeColor() {
      return globalConfig.state.themeColor;
    }
  },

  filters: {},

  components: {
    ListenChoosePic,
    ListenFillBlank,
    ListenChooseAnswer,
    ListenDialogChoose,
    ListenReport,
    ListenAnswer,
    ListenPassageAnswer,
    unitTextSpeak,
    IdentifySymbol,
    AfterListen,
    TopicExposition,
    SceneAnswer
  },

  data() {
    return {
      questionList: [],
      // 当前导航地址
      template: '',
      gameId: '',
      // 标题是否吸顶
      titleFixedStatus: false,
      titleContainerHeight: 'no_data'
    };
  },

  mounted() {
    this.getExamPort();
  },

  methods: {
    outerScroll(event) {
      const scrollTop = event.target.scrollTop;
      if (scrollTop > 570) {
        this.titleFixedStatus = true;
      } else {
        this.titleFixedStatus = false;
      }
    },

    getExamPort() {
      fetchExamReport({
        bookId: this.bookId,
        bookChapterId: this.chapterId,
        typeId: this.typeId,
        source: this.source
      }).then(response => {
        hideLoading();
        const { data } = response;
        this.questionList = data;
        const { template, game_id: gameId } = data[0];
        this.template = template;
        this.gameId = gameId;
        this.columnShow(this.handleFormatDataToArr(data));
      });
    },

    handleFormatDataToArr(arr) {
      const titleArr = [];
      const scoreArr = [];
      const allScoreArr = [];
      let sumScores = 0;
      let myScores = 0;
      arr.forEach(item => {
        const { title, score, sumScore } = item;
        myScores += Number(score);
        sumScores += Number(sumScore);
        titleArr.push({ name: title, max: sumScore });
        scoreArr.push(score);
        allScoreArr.push(sumScore);
      });

      return {
        titleArr,
        scoreArr,
        allScoreArr,
        myScores: Number(myScores).toFixed(2),
        sumScores: Number(sumScores).toFixed(2)
      };
    },

    columnShow(obj) {
      const { titleArr, scoreArr, allScoreArr, myScores, sumScores } = obj;
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('main'));
      // 指定图表的配置项和数据
      var option = {
        tooltip: {},
        legend: {
          data: [`总分(${sumScores})`, `我的得分(${myScores})`]
        },
        radar: {
          indicator: titleArr,
          name: {
            textStyle: {
              bordrRadius: 3,
              padding: [3, 5]
            }
          }
        },
        series: [
          {
            type: 'radar',
            data: [
              {
                value: allScoreArr,
                name: `总分(${sumScores})`
              },
              {
                value: scoreArr,
                name: `我的得分(${myScores})`
              }
            ]
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    chooseTemplate(item) {
      const { template, game_id: gameId } = item;
      if (
        this.template === template.trim() &&
        Number(gameId) === Number(this.gameId)
      ) {
        return;
      }
      globalConfig.commit('stopAudio');
      this.template = template.trim();
      this.gameId = gameId;
    }
  }
};
</script>

<style lang="less" scoped>
.inner_outer_page {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  .title_static {
    background: white;
    .title_box {
      height: 70px;
      line-height: 70px;
      text-align: center;
    }
    #main {
      margin: 0 auto;
    }
  }
  .right_content {
    flex: 2;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-left: solid 1px #f5f5f5;
    background: white;
    overflow-x: auto;
    .nav_scroll {
      top: 0;
      z-index: 100;
      white-space: nowrap;
      box-sizing: border-box;
      height: 70px;
      line-height: 70px;
      overflow-x: scroll;
      overflow-y: hidden;
      &.fixed_title {
        position: fixed;
        top: 136px;
        left: 5%;
        width: 90%;
        border-bottom: solid 1px #f5f5f5;
        background: white;
      }
      .nav_title {
        display: inline-block;
        text-align: center;
        padding: 0 20px;
      }
    }
    .empty_nav {
      height: 70px;
    }
    .inner_content {
      height: 100%;
      overflow-y: scroll;
    }
    .empty_line {
      height: 20px;
      width: 100%;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .inner_outer_page {
    flex-direction: column;
    overflow-y: scroll;
  }
  .title_static {
    width: 100%;
    #main {
      margin: 0 auto;
    }
  }
  .right_content {
    overflow: unset !important;
    border-bottom: solid 1px #f5f5f5;
    box-sizing: border-box;
    background-color: white;
  }
  .right_content {
    flex: none !important;
    height: auto !important;
  }
}
</style>
